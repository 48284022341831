import { createAsyncThunk } from "@reduxjs/toolkit";
import { ErrorResponse, OrderDetailPagedResult, RollingStockLengthDetail } from "../types/types";
import { AxiosError } from "axios";
import { objectToQueryString } from "../helpers/objectToQueryString";
import { axiosWithAuth } from "../app/axios";

export type FetchOpenRollingsOrdersRequest = {
  vmi?: boolean;
  rollingSequence: string;
  rollingCycleId: string;
  identifier?: string;
};

export const fetchOpenRollingsOrders = createAsyncThunk<
  OrderDetailPagedResult,
  FetchOpenRollingsOrdersRequest,
  { rejectValue: string | ErrorResponse }
>("rollings/fetchOpenRollingsOrders", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/internal/order/open_rolling_orders${query}`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue(error.response?.data);
        case 404:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue retieving open rolling orders."
          );
      }
    }
    return rejectWithValue("There was an issue retieving open rolling orders.");
  }
});

export type SiteResourcesResult = {
  site: string;
  resources: string[];
}[];

export const fetchSiteResources = createAsyncThunk<
  SiteResourcesResult,
  string | undefined,
  { rejectValue: string | ErrorResponse }
>("rollings/fetchSiteResources", async (customerId, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/rollings/site_resources${
        customerId ? "?customerId=" + customerId : ""
      }`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 401:
          return rejectWithValue(error.response?.data);
        case 403:
          return rejectWithValue(error.response?.data);
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue retieving site resources."
          );
      }
    }
    return rejectWithValue("There was an issue retieving site resources.");
  }
});

export const fetchRollingStockLengthsDetail = createAsyncThunk<
  RollingStockLengthDetail[],
  FetchOpenRollingsOrdersRequest,
  { rejectValue: string | ErrorResponse }
>("rollings/fetchRollingStockLengthsDetail", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/internal/rollings/stock_lengths${query}`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue(error.response?.data);
        case 404:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue retieving rolling stock lengths detail."
          );
      }
    }
    return rejectWithValue("There was an issue retieving rolling stock lengths detail.");
  }
});
