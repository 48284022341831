/** @jsxImportSource @emotion/react */
import {
  faBackwardStep,
  faCaretLeft,
  faCaretRight,
  faForwardStep,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import tw from "twin.macro";
import spinner from "../../assets/images/spinner.gif";
import Select from "../atoms/Select";

export type PaginationValues = {
  page?: number;
  pageSize?: number;
  start?: number;
  limit?: number;
};

type Props = {
  children?: ReactNode;
  items?: number;
  pageSize?: number;
  isLoading: boolean;
  onChange: (value: PaginationValues) => void;
  currentPage?: number;
};

const Pagination: React.FC<Props> = ({
  currentPage,
  items = 0,
  isLoading,
  onChange,
  pageSize = 25,
}) => {
  const [page, setPage] = useState(currentPage ?? 1);
  const maxPage = Math.ceil(items / pageSize);
  const currentFrom = (page - 1) * pageSize + 1;
  const currentTo = (page - 1) * pageSize + pageSize;
  const values = useMemo(
    () =>
      new Array(maxPage).fill(null).map((_, index) => ({
        value: index + 1,
        label: index + 1,
      })),
    [items]
  );

  const criteria = {
    page: page,
    pageSize,
  };

  useEffect(() => {
    if (currentPage === 1 || !currentPage) setPage(1);
  }, [items, currentPage]);

  return (
    <div
      css={tw`flex text-xs gap-2 items-center relative bg-gradient-to-b from-nucor-gradient-outer via-nucor-gradient-inner to-nucor-gradient-outer py-1 px-2`}
    >
      {isLoading && (
        <div
          css={tw`absolute top-0 left-0 w-full h-full bg-nucor-gradient-outer opacity-50`}
        />
      )}
      {items > 0 && (
        <div css={tw`flex items-center gap-2`}>
          <button className="ntp-paging-button"
            disabled={page === 1}
            onClick={() => {
              setPage(1);
              onChange({
                ...criteria,
                page: 1,
              });
            }}
          >
            <FontAwesomeIcon
              size="lg"
              css={[tw`text-nucor-green`, page === 1 && tw`text-nucor-gray`]}
              icon={faBackwardStep}
            />
          </button>
          <button className="ntp-paging-button"
            disabled={page === 1}
            onClick={() =>
              setPage((prev) => {
                if (prev === 1) return 1;
                onChange({
                  ...criteria,
                  page: prev === 1 ? 1 : prev - 1,
                });
                return prev - 1;
              })
            }
          >
            <FontAwesomeIcon
              css={[tw`text-nucor-green`, page === 1 && tw`text-nucor-gray`]}
              size="lg"
              icon={faCaretLeft}
            />
          </button>
          <Seperator />
          <span css={tw`flex gap-1 items-center select-none`}>
            Page
            <Select
              minWidth="8ch"
              data={values}
              onChange={(value: number) => {
                if (value === page) return;
                setPage(value);
                onChange({ ...criteria, page: value });
              }}
              value={page}
              name="currentPage"
            />
            <span>of</span> {maxPage}
          </span>
          <Seperator />
          <button className="ntp-paging-button" 
            disabled={page === maxPage}
            onClick={() =>
                setPage((prev) => {
                  if (prev === maxPage) return maxPage;
                  onChange({
                    ...criteria,
                    page: prev === maxPage ? maxPage : prev + 1,
                  });
                  return prev + 1;
                })
            }
          >
            <FontAwesomeIcon
              css={[
                tw`text-nucor-green cursor-pointer`,
                page === maxPage && tw`text-nucor-gray cursor-default`,
              ]}
              size="lg"
              icon={faCaretRight}
            />
          </button>
          <button className="ntp-paging-button" 
            disabled={page === maxPage}
            onClick={() => {
              setPage(maxPage);
              onChange({ ...criteria, page: maxPage });
            }}
          >
            <FontAwesomeIcon
              css={[
                tw`text-nucor-green`,
                page === maxPage && tw`text-nucor-gray`,
              ]}
              size="lg"
              icon={faForwardStep}
            />
          </button>
        </div>
      )}
      {isLoading && <img css={tw`h-4`} src={spinner} />}
      <div css={tw`flex-1 text-right select-none`}>
        {items > 0
          ? `${currentFrom} - ${
              currentTo < items ? currentTo : items
            } of ${items}`
          : isLoading
          ? "Loading"
          : "No Results"}
      </div>
    </div>
  );
};

export default Pagination;

const Seperator = () => {
  return <span tw="h-full w-[1px] inline-block" />;
};
