/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { useAppSelector } from "../../../../app/hooks";
import Select from "../../../../components/atoms/Select";
import {
  DimensionSelections,
  HeightSelection,
  ProductSelectionDetails,
  ProductShape,
} from "../../../../types/types";

type Props = {
  children?: ReactNode;
  onChange: (dimensions: ProductSelectionDetails) => void;
  value: ProductSelectionDetails;
};

const DimensionsSelectComponent: React.FC<Props> = ({ value, onChange }) => {
  const productSelections = useAppSelector(
    (state) => state.product.productSelections
  );
  const grades = productSelections?.gradeSelections.map((grade) => grade) ?? [];
  const shapes =
    grades?.find((item) => item.grade === value.grade)?.shapeSelections ?? [];
  const widths =
    shapes?.find((item) => item.shape === value.shape)?.dimensionSelections ??
    [];
  const heights =
    value.shape === "Rectangle"
      ? (widths as HeightSelection[])?.find(
          (item: HeightSelection) => item.dimension === value.width
        )?.dimensionSelections ?? []
      : [];
  const wallSelectionSource =
    value.shape === "Rectangle"
      ? heights.find((item) => item.dimension === value.height)
      : (widths as DimensionSelections[])?.find(
          (item) => item.dimension === value.width
        );
  const walls = wallSelectionSource?.wallSelections ?? [];

  return (
    <>
      <fieldset css={tw`mt-2`}>
        <label css={tw`block`}>Grade</label>
        <Select
          name="grade"
          disabled={grades.length === 0}
          minWidth="22ch"
          value={value?.grade ?? ""}
          onChange={(grade: string) => {
            if (grade !== value.grade) {
              onChange({ ...value, grade, shape: "Unknown", width: 0, height: 0, wall: 0 });
            }
          }}
          data={[
            { value: "", label: "Select" },
            ...grades.map((grade) => {
              return { value: grade.grade, label: grade.grade };
            }),
          ]}
        />
      </fieldset>
      <fieldset css={tw`mt-2`}>
        <label css={tw`block`}>Shape</label>
        <Select
          disabled={shapes.length === 0}
          name="shape"
          minWidth="22ch"
          value={value?.shape ?? "Unknown"}
          onChange={(shape: ProductShape) => {
            if (shape !== value.shape) {
              onChange({ ...value, shape, width: 0, height: 0, wall: 0 });
            }
          }}
          data={[
            { value: "Unknown", label: "Select" },
            ...shapes.map((shape) => {
              return { value: shape.shape, label: shape.shape };
            }),
          ]}
        />
      </fieldset>
      <fieldset css={tw`mt-2`}>
        <label css={tw`block`}>Width</label>
        <Select
          disabled={widths.length === 0}
          name="width"
          minWidth="22ch"
          value={value?.width ?? 0}
          onChange={(width: number) => {
            if (width !== value.width) {
              onChange({ ...value, width, height: 0, wall: 0 });
            }
          }}
          data={[
            { value: 0, label: "Select" },
            ...widths.map((width) => ({
              value: width.dimension,
              label: width.dimensionLabel,
            })),
          ]}
        />
      </fieldset>
      {value?.shape === "Rectangle" && (
        <fieldset css={tw`mt-2`}>
          <label css={tw`block`}>Height</label>
          <Select
            disabled={heights.length === 0}
            name="height"
            minWidth="22ch"
            value={value?.height ?? 0}
            onChange={(height: number) => {
              if (height !== value.height) {
                onChange({ ...value, height, wall: 0 });
              }
            }}
            data={[
              { value: 0, label: "Select" },
              ...heights.map((height) => ({
                value: height.dimension,
                label: height.dimension.toString(),
              })),
            ]}
          />
        </fieldset>
      )}
      <fieldset css={tw`mt-2`}>
        <label css={tw`block`}>Wall Thickness</label>
        <Select
          disabled={walls.length === 0}
          name="wall"
          minWidth="22ch"
          value={value?.wall ?? 0}
          onChange={(wall: number) => {
            if (wall != value.wall) {
              onChange({ ...value, wall });
            }
          }}
          data={[
            { value: 0, label: "Select" },
            ...walls.map((wall) => ({
              value: wall.decimalWallThickness,
              label: wall.wall,
            })),
          ]}
        />
      </fieldset>
    </>
  );
};

export default DimensionsSelectComponent;
