import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWithAuth } from "../app/axios";
import {
  CustomerIndex,
  ShipToRequest,
  AccountSummary,
  Customer_Customer,
  SalesTeam,
  ErrorResponse,
  CustomerSummaryItemResult,
  CustomerSummaryRequest,
  TotalByRep
} from "../types/types";
import { AxiosError } from "axios";
import { fetchCartSummary, fetchLastShipTo } from "./shoppingCart.actions";
import { fetchCustomerParts } from "./parts.actions";
import { getSiteSelectionList } from "./app.actions";
import { objectToQueryString } from "../helpers/objectToQueryString";
import { downloadFile } from "../helpers/downloadExcel";
import { isBlobError } from "../types/predicates";
import { blobToErrorResponse } from "../helpers/blobToErrorResponse";

export const setCurrentCustomerDetails = createAsyncThunk<
  Customer_Customer,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "customer/setCurrentCustomerDetails",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/customer/${id}`
      );
      dispatch(fetchCartSummary(id));
      dispatch(fetchCustomerParts(id));
      dispatch(getSiteSelectionList(id));
      dispatch(fetchLastShipTo(id));

      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue("Error fetching customer by id.");
        }
      } else {
        return rejectWithValue("Error fetching customer by id.");
      }
    }
  }
);

export const fetchCustomers = createAsyncThunk<
  CustomerIndex[],
  undefined,
  { rejectValue: string | ErrorResponse }
>("customer/fetchCustomers", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/internal/customer`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid id supplied");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("Error fetching customers.");
      }
    } else {
      return rejectWithValue("Error fetching customers.");
    }
  }
});

type RequestNewShipToRequest = {
  customerId: string;
  body: ShipToRequest;
};

export const requestNewShipTo = createAsyncThunk<
  ShipToRequest,
  RequestNewShipToRequest,
  { rejectValue: string | ErrorResponse }
>("customer/requestNewShipTo", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.post(
      `${process.env.REACT_APP_API_URL}/ship_to_request/${params.customerId}`,
      params.body
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid ship to request");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue requesting a new ship to."
          );
      }
    } else {
      return rejectWithValue("There was an issue requesting a new ship to.");
    }
  }
});

export const fetchCustomerSummary = createAsyncThunk<
  AccountSummary,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "customer/fetchCustomerSummary",
  async (customerId: string, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/customer/${customerId}/summary`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue fetching the customer view."
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue fetching the customer view."
        );
      }
    }
  }
);

export const fetchSalesTeam = createAsyncThunk<
  SalesTeam,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "customer/fetchSalesTeam",
  async (customerId: string, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/customer/${customerId}/sales_team`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          default:
            return rejectWithValue("There was an issue fetching the sale team");
        }
      } else {
        return rejectWithValue("There was an issue fetching the sales team");
      }
    }
  }
);

export const fetchCustomerSummaryList = createAsyncThunk<
CustomerSummaryItemResult,
CustomerSummaryRequest,
  { rejectValue: string | ErrorResponse }
>(
  "customer/fetchCustomerSummaryList",
  async (params, { rejectWithValue }) => {
    try {
      const query = objectToQueryString(params);
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/internal/customer/customer_summary${query}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(
          error.response?.data ?? "There was an issue fetching the customer summary."
        );
      } else {
        return rejectWithValue(
          "There was an issue fetching the customer view."
        );
      }
    }
  }
);

export const fetchCustomerSummaryListExcel = createAsyncThunk<
  void,
  CustomerSummaryRequest,
  { rejectValue: string | ErrorResponse }
>("document/fetchCustomerSummaryListExcel", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/internal/customer/customer_summary/excel${query}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      return rejectWithValue(
        error.response?.data ?? "There was an issue retrieving order documents excel sheet"
      );
    } else {
      return rejectWithValue(
        "There was an issue retrieving order documents excel sheet"
      );
    }
  }
});

export const fetchInsideShortPays = createAsyncThunk<
  TotalByRep[],
  undefined,
  { rejectValue: string | ErrorResponse }
>("customer/fetchInsideShortPays", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/internal/customer/short_pays?salesRepType=inside`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid id supplied");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("Error fetching short pay list.");
      }
    } else {
      return rejectWithValue("Error fetching short pay list.");
    }
  }
});