import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import {
  CartHeader,
  CartItem,
  CartSummaryView,
  DateString,
  RequestStatus,
} from "../types/types";
import {
  clearQuoteCart,
  fetchQuoteCart,
  fetchQuoteCartItemInstructions,
  fetchQuoteCartSummaries,
  removeQuoteCartItem,
  submitQuoteCart,
  updateQuoteCartItems,
  updateQuoteCartItemInstructions,
  updateQuoteItemPrice
} from "./quoteCart.actions";

type InitialState = {
  currentQuoteCart?: CartHeader;
  quoteCartSummaries?: CartSummaryView[];
  status: RequestStatus;
};

const initialState: InitialState = {
  status: "idle",
};

const quoteCartSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    updateAllDueDates: (state, action: PayloadAction<DateString>) => {
      if (!state.currentQuoteCart?.sites) return;
      state.currentQuoteCart.sites = state.currentQuoteCart.sites.map(
        (site) => {
          return {
            ...site,
            items: site.items.map((item) => {
              return {
                ...item,
                requestedShippingDate: action.payload,
              };
            }),
          };
        }
      );
    },
    updatePoLineNumber: (
      state,
      action: PayloadAction<{ id: number; lineNumber?: number }>
    ) => {
      if (state.currentQuoteCart) {
        state.currentQuoteCart.sites = state.currentQuoteCart.sites.map(
          (site) => {
            return {
              ...site,
              items: site.items.map((item) => {
                if (item.id === action.payload.id) {
                  return {
                    ...item,
                    poLineNumber: action.payload.lineNumber,
                  };
                } else {
                  return item;
                }
              }),
            };
          }
        );
      }
    },
    updateItemDueDate: (
      state,
      action: PayloadAction<{ id: number; date?: DateString }>
    ) => {
      if (state.currentQuoteCart) {
        state.currentQuoteCart.sites = state.currentQuoteCart.sites.map(
          (site) => {
            return {
              ...site,
              items: site.items.map((item) => {
                if (item.id === action.payload.id) {
                  return {
                    ...item,
                    requestedShippingDate: action.payload.date,
                  };
                } else {
                  return item;
                }
              }),
            };
          }
        );
      }
    },
    updateCustomerPartNumber: (
      state,
      action: PayloadAction<{ id: number; customerPartNumber: string }>
    ) => {
      if (state.currentQuoteCart) {
        state.currentQuoteCart.sites = state.currentQuoteCart.sites.map(
          (site) => {
            return {
              ...site,
              items: site.items.map((item) => {
                if (item.id === action.payload.id) {
                  return {
                    ...item,
                    customerPartNumber: action.payload.customerPartNumber,
                    newCustomerPartNumber: "",
                  };
                } else {
                  return item;
                }
              }),
            };
          }
        );
      }
    },
    updateNewCustomerPartNumber: (
      state,
      action: PayloadAction<{ id: number; partNumber: string }>
    ) => {
      if (!state.currentQuoteCart) return;
      state.currentQuoteCart.sites = state.currentQuoteCart.sites.map(
        (site) => {
          return {
            ...site,
            items: site.items.map((item) => {
              return item.id === action.payload.id
                ? {
                    ...item,
                    newCustomerPartNumber: action.payload.partNumber,
                    customerPartNumber: "",
                  }
                : item;
            }),
          };
        }
      );
    },
    clearCurrentQuoteCart: (state) => {
      state.currentQuoteCart = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuoteCartSummaries.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchQuoteCartSummaries.fulfilled, (state, action) => {
      state.status = "success";
      state.quoteCartSummaries = action.payload;
    });
    builder.addCase(fetchQuoteCartSummaries.rejected, (state) => {
      state.status = "error";
    });
    builder.addCase(fetchQuoteCart.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchQuoteCart.fulfilled, (state, action) => {
      state.status = "success";
      state.currentQuoteCart = action.payload;
    });
    builder.addCase(fetchQuoteCart.rejected, (state) => {
      state.status = "error";
    });
    builder.addCase(fetchQuoteCartItemInstructions.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchQuoteCartItemInstructions.fulfilled, (state) => {
      state.status = "success";
    });
    builder.addCase(fetchQuoteCartItemInstructions.rejected, (state) => {
      state.status = "error";
    });
    builder.addCase(submitQuoteCart.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(submitQuoteCart.fulfilled, (state, action) => {
      state.currentQuoteCart = action.payload;
      state.status = "success";
    });
    builder.addCase(submitQuoteCart.rejected, (state) => {
      state.status = "error";
    });
    builder.addCase(updateQuoteCartItems.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateQuoteCartItems.fulfilled, (state, action) => {
      state.status = "success";
      state.currentQuoteCart = action.payload;
    });
    builder.addCase(updateQuoteCartItems.rejected, (state) => {
      state.status = "error";
    });
    builder.addCase(updateQuoteCartItemInstructions.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(
      updateQuoteCartItemInstructions.fulfilled,
      (state, action) => {
        state.status = "success";
        state.currentQuoteCart = action.payload;
      }
    );
    builder.addCase(updateQuoteCartItemInstructions.rejected, (state) => {
      state.status = "error";
    });
    builder.addCase(removeQuoteCartItem.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(removeQuoteCartItem.fulfilled, (state, action) => {
      state.status = "success";
      state.currentQuoteCart = action.payload;
    });
    builder.addCase(removeQuoteCartItem.rejected, (state) => {
      state.status = "error";
    });
    builder.addCase(clearQuoteCart.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(clearQuoteCart.fulfilled, (state) => {
      state.status = "success";
      state.currentQuoteCart = undefined;
    });
    builder.addCase(clearQuoteCart.rejected, (state) => {
      state.status = "error";
    });
    builder.addCase(updateQuoteItemPrice.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateQuoteItemPrice.fulfilled, (state, action) => {
      state.status = "success";
      state.currentQuoteCart = action.payload;
    });
    builder.addCase(updateQuoteItemPrice.rejected, (state) => {
      state.status = "error"
    });
  },
});

export const selectQuoteCartSummary = (state: RootState) =>
  state.quoteCart.quoteCartSummaries;

export const selectCurrentQuoteCart = (state: RootState) =>
  state.quoteCart.currentQuoteCart;

export const selectRollingItemsInQuoteCartByDescription = createSelector(
  [
    (state: RootState) =>
      state.quoteCart.currentQuoteCart?.sites.reduce((acc, current) => {
        return [...acc, ...current.items];
      }, [] as CartItem[]),
    (_, compoundProductKey?: string) => compoundProductKey,
  ],
  (items, compoundProductKey) => {
    return items?.filter(
      (item) =>
        item.allocationSource === "Rolling" &&
        item.productIndex.compoundProductKey === compoundProductKey
    );
  }
);

export const selectQuoteRollings = (state: RootState) =>
  state.quoteCart.currentQuoteCart?.sites.reduce((acc, current) => {
    return [
      ...acc,
      ...current.items.filter((item) => item.allocationSource === "Rolling"),
    ];
  }, [] as CartItem[]) ?? [];

export default quoteCartSlice.reducer;
export const {
  updateAllDueDates,
  updatePoLineNumber,
  updateItemDueDate,
  updateCustomerPartNumber,
  clearCurrentQuoteCart,
  updateNewCustomerPartNumber,
} = quoteCartSlice.actions;
