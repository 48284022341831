/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { CartHeader } from "../../../../../types/types";
import SoldTo from "../components/SoldTo";
import ShipTo from "../components/ShipTo";
import OrderDetails from "./components/OrderDetails";
import ReviewShoppingSite from "./components/ReviewShoppingSite";
import { numberFormatter } from "../../../../../helpers/numberFormat";
import { Link, useLocation } from "react-router-dom";
import Disclaimer from "./components/Disclaimer";

type Props = {
  children?: ReactNode;
  cart?: CartHeader;
};

const ShoppingCartConfirmation: React.FC<Props> = ({ cart }) => {
  const location = useLocation();

  return (
    <div css={tw`w-full text-xs p-2`}>
      <p css={tw`mt-4`}>
        Thank you for your order! Check your email for a confirmation.
      </p>
      <p css={tw`my-1`}>
        To create a new order, click{" "}
        <Link
          css={tw`text-nucor-link underline font-semibold hover:(text-nucor-link-hover no-underline)`}
          to={{ pathname: "/portal/stock", search: location.search }}
        >
          here
        </Link>
      </p>
      <h2 css={tw`text-red-600 text-lg font-bold flex-1 mt-1`}>
        <span>Confirm Order</span> <span>{cart?.orderNumbers?.[0] ?? ""}</span>
      </h2>
      {cart?.customerPONumber ? (
        <div css={tw`text-white bg-nucor-gray font-semibold px-2 py-1 my-1`}>
          {`Purchase Order: ${cart?.customerPONumber}`}
        </div>
      ) : null}
      <div css={tw`bg-[#e6e6e6] flex justify-evenly items-start p-1 w-full`}>
        <div css={tw`w-full`}>
          <SoldTo customer={cart?.billingCustomer} />
        </div>
        <div css={tw`w-full`}>
          <ShipTo customer={cart?.shipTo} />
        </div>
        <div css={tw`w-full`}>
          <OrderDetails cart={cart} confirmation />
        </div>
      </div>
      {cart?.sites.map((site, index) => (
        <ReviewShoppingSite
          hasItemWithPartList={cart.hasItemWithPartList}
          site={site}
          key={index}
          currency={cart.currency}
        />
      ))}
      {cart?.orderTotals ? (
        <div css={tw`flex items-center mt-4`}>
        <p css={tw`mx-4 text-nucor-gray font-bold`}>On Order Totals:</p>
        <table className="ntp-portal-table ntp-remove-hover ntp-remove-stripe">
          <thead>
            <tr>
              <th css={tw`!px-1`}>Pcs</th>
              <th css={tw`!px-1`}>Lbs</th>
              {cart?.orderTotals?.charges.map((item, index) => (
                <th key={index}>{item.name}</th>
              ))}
              <th css={tw`!px-1`}>Total Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr css={tw`text-right`}>
              <td>{numberFormatter(cart?.orderTotals?.pieces)}</td>
              <td>{numberFormatter(cart?.orderTotals?.pounds)}</td>
              {cart?.orderTotals?.charges.map((item, index) => (
                <td key={index}>
                  {Intl.NumberFormat("en-US", {
                    currency: "USD",
                    style: "currency",
                  }).format(item.charge)} {cart?.currency}
                </td>
              ))}
              <td>
                {Intl.NumberFormat("en-US", {
                  currency: "USD",
                  style: "currency",
                }).format(cart?.orderTotals?.totalCost)} {cart?.currency}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      ) : null}
      <Disclaimer />
    </div>
  );
};

export default ShoppingCartConfirmation;
