/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import Button from "../../../../../components/atoms/Button";
import Loading from "../../../../../components/atoms/Loading";
import { submitCart } from "../../../../../store/shoppingCart.actions";
import { selectCurrentCustomerId } from "../../../../../store/customer.reducer";
import OrderDetails from "./components/OrderDetails";
import ReviewShoppingSite from "./components/ReviewShoppingSite";
import ShipTo from "../components/ShipTo";
import SoldTo from "../components/SoldTo";
import { CartHeader, ErrorResponse } from "../../../../../types/types";
import ShoppingCartConfirmation from "./ShoppingCartConfirmation";
import { numberFormatter } from "../../../../../helpers/numberFormat";
import ConfirmShipTo from "./components/ConfirmShipTo";
import { clearShoppingCart } from "../../../../../store/shoppingCart.reducer";
import Disclaimer from "./components/Disclaimer";
import { sendShoppingCartAnalytics } from "../../../../../store/analytics.actions";
import Toast from "../../../../../components/molecules/Toast";

type Props = {
  children?: ReactNode;
};

const ReviewShoppingCart: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { currentShoppingCart } = useAppSelector((state) => state.shoppingCart);
  const login = useAppSelector(
    (state) => state.user.currentContext?.user.email
  );
  const customerId = useAppSelector(selectCurrentCustomerId);
  const confirmShipToOnSubmit = useAppSelector(
    (state) => state.user.currentContext?.userPreferences?.confirmShipToOnSubmit
  );

  const location = useLocation();
  const navigate = useNavigate();
  const initialRender = useRef(true);
  const [status, setStatus] = useState<"idle" | "sending" | "sent" | "error">(
    "idle"
  );
  const [cart, setCart] = useState<CartHeader | undefined>(
    location.state as CartHeader
  );

  const [showConfirmShipTo, setShowConfirmShipTo] = useState(false);

  const [cartErrors, setCartErrors] = useState<string | ErrorResponse>();

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      setCart(undefined);
      navigate(`/portal/stock/cart/shopping?customer_id=${customerId}`);
    }
  }, [customerId]);

  if (cart && status === "sent") {
    return <ShoppingCartConfirmation cart={cart} />;
  }

  if (location.state) {
    return <ShoppingCartConfirmation cart={location.state} />;
  }
  
  if (!currentShoppingCart || currentShoppingCart.empty) {
    return (
      <div css={tw`w-full text-left p-4`}>
        <h2 css={tw`text-red-600 text-lg font-bold flex-1`}>Review Order</h2>
        <p css={tw`text-xs p-4`}>There are no items in the shopping cart.</p>
      </div>
    );
  }

  const editHandler = () => {
    navigate(`/portal/stock/cart/shopping?customer_id=${customerId}`);
  };

  const submitHandler = () => {
    if (!customerId) return;

    setCartErrors(undefined);
    setStatus("sending");
    dispatch(submitCart(customerId))
      .unwrap()
      .then((cart) => {
        if (cart.orderSubmitted) {
          setCart({
            ...currentShoppingCart,
            orderSubmitted: cart.orderSubmitted,
            errors: cart.errors,
            warnings: cart.warnings,
            valid: cart.valid,
            orderStatus: cart.orderStatus,
            orderDate: cart.orderDate,
            orderNumbers: cart.orderNumbers,
          });
          setStatus("sent");
          dispatch(clearShoppingCart());
          dispatch(
            sendShoppingCartAnalytics({
              ...cart,
              login: login as string,
            })
          );
        } else {
          setStatus("idle");  
        }
      })
      .catch((error) => {
        setCartErrors(error);
        setStatus("error");
      })
  };

  return (
    <div css={tw`p-1 w-full text-xs relative`}>
      {cartErrors && (
        <Toast
          type="error"
          onConfirm={() => setCartErrors(undefined)}
          message={cartErrors}
        />
      )}

      {status === "sending" && <Loading>Submitting Cart</Loading>}
      {showConfirmShipTo && (
        <ConfirmShipTo
          onCancel={() => setShowConfirmShipTo(false)}
          onConfirm={() => {
            setShowConfirmShipTo(false);
            submitHandler();
          }}
          title="Confirm Ship To"
        >
          <p css={tw`font-semibold mb-1`}>
            Use the following ship to customer?
          </p>
          <p>{currentShoppingCart?.shipTo.name}</p>
          <p>{currentShoppingCart?.shipTo.address.address1}</p>
          <p>{currentShoppingCart?.shipTo.address.address2}</p>
          <p>{currentShoppingCart?.shipTo.address.address3}</p>
          <p>{`${currentShoppingCart?.shipTo.address.city}, ${currentShoppingCart?.shipTo.address.stateCode} ${currentShoppingCart?.shipTo.address.postalCode}`}</p>
        </ConfirmShipTo>
      )}
      <div css={tw`relative mt-2 self-start w-full`}>
        <h2 css={tw`text-red-600 text-lg font-bold flex-1`}>Review Order</h2>
      </div>
      <ul css={tw`list-disc list-outside ml-6 mt-2 mb-3`}>
        {currentShoppingCart?.warnings?.map((warning, index) => (
          <pre key={index}>
            <li css={tw`text-nucor-green`}>{warning}</li>
          </pre>
        ))}
      </ul>
      {currentShoppingCart?.errors.length > 0 && (
        <>
          <p>
            Please resolve the following before submitting the shopping cart:
          </p>
          <ul css={tw`list-disc list-outside ml-6 mt-2 mb-3`}>
            {currentShoppingCart?.errors?.map((error, index) => (
              <pre key={index}>
                <li css={tw`text-red-600`}>{error}</li>
              </pre>
            ))}
          </ul>
        </>
      )}
      <div css={tw`my-2 flex items-center gap-4`}>
        <span>Review the order and click submit or edit.</span>
        <Button disabled={status === "sending"} onClick={editHandler}>
          Edit
        </Button>
        <Button
          disabled={status === "sending"}
          onClick={() => {
            if (confirmShipToOnSubmit) {
              setShowConfirmShipTo(true);
            } else {
              submitHandler();
            }
          }}
        >
          Submit
        </Button>
      </div>
      {currentShoppingCart?.customerPONumber ? (
        <div css={tw`text-white bg-nucor-gray font-semibold px-2 py-1 my-1`}>
          {`Purchase Order: ${currentShoppingCart?.customerPONumber}`}
        </div>
      ) : null}
      <div css={tw`bg-[#e6e6e6] flex justify-evenly items-start p-1 w-full`}>
        <div css={tw`w-full`}>
          <SoldTo customer={currentShoppingCart?.billingCustomer} />
        </div>
        <div css={tw`w-full`}>
          <ShipTo customer={currentShoppingCart?.shipTo} />
        </div>
        <div css={tw`w-full`}>
          <OrderDetails cart={currentShoppingCart} confirmation />
        </div>
      </div>
      {currentShoppingCart?.sites.map((site, index) => (
        <ReviewShoppingSite
          hasItemWithPartList={currentShoppingCart?.hasItemWithPartList}
          site={site}
          key={index}
          currency={currentShoppingCart.currency}
        />
      ))}
      <div css={tw`flex items-center mt-4`}>
        <p css={tw`mx-4 text-nucor-gray font-bold`}>On Order Totals:</p>
        <table className="ntp-portal-table ntp-remove-hover ntp-remove-stripe">
          <thead>
            <tr>
              <th css={tw`!px-1`}>Pcs</th>
              <th css={tw`!px-1`}>Lbs</th>
              {currentShoppingCart?.orderTotals?.charges.map((item, index) => (
                <th key={index}>{item.name}</th>
              ))}
              <th css={tw`!px-1`}>Total Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr css={tw`text-right`}>
              <td>{numberFormatter(currentShoppingCart?.orderTotals?.pieces)}</td>
              <td>
                {numberFormatter(currentShoppingCart?.orderTotals?.pounds)}
              </td>
              {currentShoppingCart?.orderTotals?.charges.map((item, index) => (
                <td key={index}>
                  {Intl.NumberFormat("en-US", {
                    currency: "USD",
                    style: "currency",
                  }).format(item.charge)} {currentShoppingCart?.currency}
                </td>
              ))}
              <td>
                {Intl.NumberFormat("en-US", {
                  currency: "USD",
                  style: "currency",
                }).format(currentShoppingCart?.orderTotals?.totalCost)} {currentShoppingCart?.currency}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Disclaimer />
    </div>
  );
};

export default ReviewShoppingCart;
