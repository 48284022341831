/** @jsxImportSource @emotion/react */
import React from "react";
import tw from "twin.macro";
import Link from "../../../../../../components/atoms/Link";
import { CartHeader } from "../../../../../../types/types";
import SoldTo from "../../components/SoldTo";
import ShipTo from "../../components/ShipTo";
import { numberFormatter } from "../../../../../../helpers/numberFormat";
import OrderDetails from "./OrderDetails";
import ReviewOrderSite from "./ReviewOrderSite";
import Disclaimer from "./Disclaimer";
import { useLocation } from "react-router-dom";

interface Props {
  cart?: CartHeader;
}

const InquiryCartConfirmation: React.FC<Props> = ({ cart }) => {
  const location = useLocation();

  return (
    <div css={tw`w-full text-xs p-2`}>
      <p css={tw`mt-4`}>
        Thank you for your inquiry! Check your email for a confirmation.
      </p>
      <p css={tw`my-1`}>
        To create a new inquiry, click{" "}
        <Link
          css={tw`text-nucor-link underline font-semibold hover:(text-nucor-link-hover no-underline)`}
          to={{ pathname: "/portal/stock", search: location.search }}
        >
          here
        </Link>
      </p>
      <h2 css={tw`text-red-600 text-lg font-bold flex-1 mt-1`}>
        <span>Inquiry Confirmation</span>{" "}
        <span>{cart?.orderNumbers?.[0] ?? ""}</span>
      </h2>
      {cart?.customerPONumber ? (
        <div css={tw`text-white bg-nucor-gray font-semibold px-2 py-1 my-1`}>
          {`Purchase Order: ${cart?.customerPONumber}`}
        </div>
      ) : null}
      <div css={tw`bg-[#e6e6e6] flex justify-evenly items-start p-1 w-full`}>
        <div css={tw`w-full`}>
          <SoldTo customer={cart?.billingCustomer} />
        </div>
        <div css={tw`w-full`}>
          <ShipTo customer={cart?.shipTo} />
        </div>
        <div css={tw`w-full`}>
          <OrderDetails cart={cart} />
        </div>
      </div>
      {cart?.sites.map((site, index) => (
        <ReviewOrderSite
          hasItemWithPartList={cart.hasItemWithPartList}
          site={site}
          key={index}
          currency={cart.currency}
        />
      ))}
      <div css={tw`flex items-center mt-4`}>
        <p css={tw`mx-4 text-nucor-gray font-bold`}>On Order Totals:</p>
        <table className="ntp-portal-table ntp-remove-hover ntp-remove-stripe">
          <thead>
            <tr>
              <th css={tw`!px-1`}>Pcs</th>
              <th css={tw`!px-1`}>Lbs</th>
            </tr>
          </thead>
          <tbody>
            <tr css={tw`text-right`}>
              <td>{cart?.totalPieces}</td>
              <td>{numberFormatter(cart?.totalWeight)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Disclaimer />
    </div>
  );
};

export default InquiryCartConfirmation;
