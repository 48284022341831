/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { useAppSelector } from "../../../../../../app/hooks";
import { numberFormatter } from "../../../../../../helpers/numberFormat";
import { CartSite } from "../../../../../../types/types";
import ReviewLineItem from "./ReviewLineItem";
import { selectCurrentAuthorities } from "../../../../../../store/user.reducer";
import { hasAuthority } from "../../../../../../helpers/hasAuthority";

type Props = {
  children?: ReactNode;
  site: CartSite;
  hasItemWithPartList?: boolean;
  currency: string;
};

const ReviewOrderSite: React.FC<Props> = ({ site, hasItemWithPartList, currency }) => {
  const {
    site: siteName,
    address: {
      address1,
      address2,
      address3,
      city,
      stateCode,
      postalCode,
      phone,
      fax,
    },
    items,
  } = site;
  const usePoLineNumber = useAppSelector(
    (state) => state.customer.currentCustomer?.useItemPoLineNumber
  );

  const totalPieces = items.reduce((acc, current) => {
    return acc + current.pieces;
  }, 0);

  const totalWeight = items.reduce((acc, current) => {
    return acc + current.weight;
  }, 0);

  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const isInternal = hasAuthority("internal", currentAuthorities);

  return (
    <div css={tw`flex mt-2`}>
      <div css={tw`bg-nucor-green w-4`} />
      <div css={tw`flex-1`}>
        <div css={tw`bg-[#e6e6e6] p-1`}>
          <p>Ship From:</p>
          <p css={tw`font-bold`}>{siteName}</p>
          <p>{address1}</p>
          <p>{address2}</p>
          <p>{address3}</p>
          <p>{`${city}, ${stateCode} ${postalCode}`}</p>
          {phone && <p>Tel: {phone}</p>}
          {fax && <p>Fax: {fax}</p>}
          <p></p>
        </div>
        <table className="ntp-portal-table ntp-remove-hover ntp-remove-stripe" css={tw`w-full`}>
          <thead>
            <tr>
              <th>Description</th>
              <th>Grade</th>
              <th>Pcs</th>
              <th>Lbs</th>
              {isInternal && <th>Price</th>}
              <th>Source</th>
              {usePoLineNumber && <th>PO Line #</th>}
              <th>Due Date</th>
              {hasItemWithPartList && <th>Part Number</th>}
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <ReviewLineItem
                hasItemWithPartList={hasItemWithPartList}
                item={item}
                key={index}
                currency={currency}
              />
            ))}
            <tr>
              <td css={tw`text-nucor-gray font-bold`} colSpan={2}>
                Sub Total:
              </td>
              <td>{totalPieces}</td>
              <td>{numberFormatter(totalWeight)}</td>
              <td colSpan={100}></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReviewOrderSite;
