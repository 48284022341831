/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";
import AnimatedLoadingSpinner from "../../../../components/atoms/AnimatedLoadingSpinner";
import { ClaimDetailResults } from "../../../../types/types";
import { currencyFormatter } from "../../../../helpers/currencyFormatter";
import { useAppDispatch } from "../../../../app/hooks";
import { fetchClaimDetail } from "../../../../store/document.actions";
import { formatDateToShort } from "../../../../helpers/dateHelpers";
import TextButton from "../../../../components/atoms/TextButton";

type Props = {
  claimId: string;
  children?: ReactNode;
  onBackClick: () => void;
};

const ClaimDetails: React.FC<Props> = ({ claimId, onBackClick }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsloading] = useState(true);
  const [claimDetail, setClaimDetail] = useState<ClaimDetailResults | null>(null);
  
  useEffect(() => {
    if (claimId)
      dispatch(fetchClaimDetail(claimId))
      .unwrap()
      .then((result) => {
        setClaimDetail(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsloading(false));
  }, [claimId]);

  return (
    <div css={tw`text-xs`}>
      {isLoading && <AnimatedLoadingSpinner css={tw`ml-4`} />}
      {!isLoading && claimDetail && (
        <>
        <div>
          <div css={tw`uppercase text-white font-bold bg-nucor-green text-xs flex justify-between py-1 px-2`}>
            <span className="w-16">Claim</span>
            <TextButton onClick={onBackClick} type="button" css={tw`text-white`}>
              Back to List
            </TextButton>
            <span className="w-16"></span>
          </div>
          </div>
          <div css={tw`grid grid-cols-6 py-[5px] bg-white px-1`}>
            <div><span tw="font-bold">Case Id:</span> {claimDetail.caseId}</div>
            <div><span tw="font-bold">Name:</span> {claimDetail.name}</div>
            <div><span tw="font-bold">Mill:</span> {claimDetail.department}</div>
            <div><span tw="font-bold">Process:</span> {claimDetail.caseProcess}</div>
            <div><span tw="font-bold">Priority:</span> {claimDetail.priority}</div>
            <div><span tw="font-bold">Value:</span> {currencyFormatter(claimDetail.value)} {claimDetail.currency}</div>
          </div>
          <div css={tw`grid grid-cols-6 py-[5px] bg-white px-1`}>
            <div><span tw="font-bold">Description:</span> {claimDetail.description}</div>
            <div><span tw="font-bold">Category:</span> {claimDetail.caseCategory}</div>
            <div><span tw="font-bold">Category Type:</span> {claimDetail.categoryType}</div>
            <div><span tw="font-bold">Employee Res:</span> {claimDetail.employeeResponsible}</div>
            <div tw="flex">
              <div tw="font-bold mr-1">Assigned To:</div> 
              <div>
                {claimDetail.assignedTo.map((person: string, index) => 
                    <div key={index}>{person}</div>)
                }
              </div>
            </div>
          </div>
          <div css={tw`grid grid-cols-6 py-[5px] bg-white px-1`}>
            <div><span tw="font-bold">Status:</span> {claimDetail.status}</div>
            <div><span tw="font-bold">Resolution:</span> {claimDetail.caseResolution}</div>
            <div tw="flex">
              <div tw="font-bold mr-1">Invoice:</div> 
              <div>
                {claimDetail.invoiceNumbers.map((invoice: string, index) => 
                    <div key={index}>{invoice}</div>)
                }
              </div>
            </div>
            <div tw="flex">
              <div tw="font-bold mr-1">Sales Order:</div> 
              <div>
                {claimDetail.salesOrderNumbers.map((so: string, index) => 
                    <div key={index}>{so}</div>)
                }
              </div>
            </div>
            <div tw="flex">
              <div tw="font-bold mr-1">Customer PO:</div> 
              <div>
                {claimDetail.salesOrderCustomerPONumbers.map((po: string, index) => 
                    <div key={index}>{po}</div>)
                }
              </div>
            </div>
          </div>
          <div css={tw`grid grid-cols-6 py-[5px] bg-white px-1`}>
            <div css={tw`col-span-3`}><span tw="font-bold">Invoice Account:</span> {claimDetail.invoiceAccount}</div>
            <div css={tw`col-span-3`}><span tw="font-bold">Customer Account:</span> {claimDetail.customerAccount}</div>
          </div>
          <div css={tw`grid grid-cols-6 py-[5px] bg-white px-1`}>
            <div><span tw="font-bold">Open Date:</span> {formatDateToShort(claimDetail.openedDate)}</div>
            <div><span tw="font-bold">Open By:</span> {claimDetail.openedBy}</div>
            <div><span tw="font-bold">Closed Date:</span> {formatDateToShort(claimDetail.closedDate)}</div>
            <div><span tw="font-bold">Closed By:</span> {claimDetail.closedBy}</div>
          </div>
          <div css={tw`grid grid-cols-6 py-[5px] bg-white px-1`}>
            <div css={tw`col-span-6`}><span tw="font-bold">Notes:</span> {claimDetail.notes}</div>
          </div>
          <div css={tw`grid grid-cols-6 py-[5px] bg-white px-1`}>
            <div><span tw="font-bold">RMA Number:</span> {claimDetail.rMANumber}</div>
            <div><span tw="font-bold">Returned Sales Order:</span> {claimDetail.returnedSalesOrder}</div>
            <div><span tw="font-bold">Return Status:</span> {claimDetail.returnStatus}</div>
          </div>
          {claimDetail.claimReturnOrderLines && claimDetail.claimReturnOrderLines.length > 0 && (
            <table className="ntp-portal-table" css={tw`2xl:w-11/12 w-full mt-2`}>
              <thead>
                <tr>
                  <th>RMA Number</th>
                  <th>Invoice Account</th>
                  <th>Customer Account</th>
                  <th>Returned Order Number</th>
                  <th>Item Number</th>
                  <th>Line Description</th>
                  <th>Length</th>
                  <th>Return Sales Qty</th>
                  <th>Sales Unit</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {claimDetail.claimReturnOrderLines.map((item, index) => (
                  <tr key={index}>
                    <td>{item.rMANumber}</td>
                    <td>{item.invoiceAccount}</td>
                    <td>{item.customerAccountNumber}</td>
                    <td>{item.returnOrderNumber}</td>
                    <td>{item.itemNumber}</td>
                    <td>{item.lineDescription}</td>
                    <td>{item.length}</td>
                    <td>{item.returnSalesQuantity}</td>
                    <td>{item.salesUnitSymbol}</td>
                    <td>{item.lineAmount} {item.currency}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </div>
  );
};

export default ClaimDetails;
