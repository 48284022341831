/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { RollingStockLengthDetail } from "../../../../../../types/types";

type Props = {
  children?: ReactNode;
  title?: string;
  list?: RollingStockLengthDetail[];
};

const StockLengthsDetail: React.FC<Props> = ({ title, list }) => {
  return (
    <div css={tw`mt-4`}>
      <span css={tw`font-bold`}>{title}</span>
      {list && list.length > 0 && (
      <table className="ntp-portal-table" css={tw`table-fixed`}>
        <thead>
          <tr>
            {list?.map((item, index) => (
              <th css={tw`min-w-[12ch]`} key={index}>{item.lengthDescription}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {list?.map((item, index) => (
              <td key={index} className="text-right">{item.formattedWeight}</td>
            ))}
          </tr>
        </tbody>
      </table>
      )}

      {(!list || list.length == 0) && (
      <div>No stock length weight for this rolling</div>
      )}
    </div>
  );
};

export default StockLengthsDetail;

