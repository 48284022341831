import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addPrimeStockToCart,
  addRollingItemToCart,
  addSecondaryToCart,
  addShortsToCart,
  changeShipTo,
  clearCustomerCart,
  fetchCartSummary,
  fetchCurrentShoppingCart,
  fetchOpenCarts,
  fetchProductInstructions,
  removeCartItem,
  removePrimeStockFromCart,
  removeRollingItemFromCart,
  removeSecondaryCartItem,
  removeShortsCartItem,
  submitCart,
  updateCartItemDetails,
  updateItemInstructions,
  updatePrimeStockCartItem,
  updateRollingCartItem,
  updateSecondaryStockCartItem,
  updateShortsStockCartItem,
  validateCart,
  updateItemPrice
} from "./shoppingCart.actions";
import {
  CartHeader,
  CartOrderAndInquirySummaryView,
  CartSummaryView,
  DateString,
  DocType,
  RequestStatus,
} from "../types/types";
import { RootState } from "./store";

type InitialState = {
  currentShoppingCart?: CartHeader;
  currentCartSummary?: CartOrderAndInquirySummaryView;
  currentShipTo?: string;
  isLoading: boolean;
  openCarts?: CartSummaryView[];
  status: RequestStatus;
};

const initialState: InitialState = {
  isLoading: false,
  status: "idle",
};

const shoppingCartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    decrementQuoteCartItemCount: (state) => {
      if (!state.currentCartSummary?.totalQuoteItems) return;
      if (state.currentCartSummary.totalQuoteItems > 0) {
        state.currentCartSummary.totalQuoteItems--;
      }
    },
    updateCustomerPONumber: (state, action: PayloadAction<string>) => {
      if (state.currentShoppingCart) {
        state.currentShoppingCart.customerPONumber = action.payload;
      }
    },
    updatePoLineNumber: (
      state,
      action: PayloadAction<{ id: number; lineNumber?: number }>
    ) => {
      if (state.currentShoppingCart) {
        state.currentShoppingCart.sites = state.currentShoppingCart.sites.map(
          (site) => {
            return {
              ...site,
              items: site.items.map((item) => {
                if (item.id === action.payload.id) {
                  return {
                    ...item,
                    poLineNumber: action.payload.lineNumber,
                  };
                } else {
                  return item;
                }
              }),
            };
          }
        );
      }
    },
    updateItemPoNumber: (
      state,
      action: PayloadAction<{ id: number; po: string }>
    ) => {
      if (state.currentShoppingCart) {
        state.currentShoppingCart.sites = state.currentShoppingCart.sites.map(
          (site) => {
            return {
              ...site,
              items: site.items.map((item) => {
                if (item.id === action.payload.id) {
                  return {
                    ...item,
                    customerPONumber: action.payload.po,
                  };
                } else {
                  return item;
                }
              }),
            };
          }
        );
      }
    },
    updateItemDueDate: (
      state,
      action: PayloadAction<{ id: number; date?: DateString }>
    ) => {
      if (state.currentShoppingCart) {
        state.currentShoppingCart.sites = state.currentShoppingCart.sites.map(
          (site) => {
            return {
              ...site,
              items: site.items.map((item) => {
                if (item.id === action.payload.id) {
                  return {
                    ...item,
                    requestedShippingDate: action.payload.date,
                  };
                } else {
                  return item;
                }
              }),
            };
          }
        );
      }
    },
    clearPoNumberFromCart: (state, action: PayloadAction<"cart" | "items">) => {
      if (!state.currentShoppingCart) return;
      if (action.payload === "cart") {
        state.currentShoppingCart.customerPONumber = undefined;
      }
      if (action.payload === "items") {
        state.currentShoppingCart.sites = state.currentShoppingCart.sites.map(
          (site) => {
            return {
              ...site,
              items: site.items.map((item) => {
                return {
                  ...item,
                  customerPONumber: undefined,
                };
              }),
            };
          }
        );
      }
    },
    updateAllDueDates: (state, action: PayloadAction<DateString>) => {
      if (!state.currentShoppingCart?.sites) return;
      state.currentShoppingCart.sites = state.currentShoppingCart.sites.map(
        (site) => {
          return {
            ...site,
            items: site.items.map((item) => {
              return {
                ...item,
                requestedShippingDate: action.payload,
              };
            }),
          };
        }
      );
    },
    updateCustomerPartNumber: (
      state,
      action: PayloadAction<{ id: number; customerPartNumber: string }>
    ) => {
      if (state.currentShoppingCart) {
        state.currentShoppingCart.sites = state.currentShoppingCart.sites.map(
          (site) => {
            return {
              ...site,
              items: site.items.map((item) => {
                if (item.id === action.payload.id) {
                  return {
                    ...item,
                    customerPartNumber: action.payload.customerPartNumber,
                    newCustomerPartNumber: "",
                  };
                } else {
                  return item;
                }
              }),
            };
          }
        );
      }
    },
    updateCartNotes: (state, action: PayloadAction<string>) => {
      if (!state.currentShoppingCart) return;
      state.currentShoppingCart.notes = action.payload;
    },
    updatePrePayAndAdd: (state, action: PayloadAction<boolean>) => {
      if (!state.currentShoppingCart) return;
      state.currentShoppingCart.prePayandAdd = action.payload;
    },
    updateDocType: (state, action: PayloadAction<DocType>) => {
      if (!state.currentShoppingCart) return;
      state.currentShoppingCart.docType = action.payload;
    },
    clearShoppingCart: (state) => {
      state.currentShoppingCart = undefined;
    },
    updateNewCustomerPartNumber: (
      state,
      action: PayloadAction<{ id: number; partNumber: string }>
    ) => {
      if (!state.currentShoppingCart) return;
      state.currentShoppingCart.sites = state.currentShoppingCart.sites.map(
        (site) => {
          return {
            ...site,
            items: site.items.map((item) => {
              return item.id === action.payload.id
                ? {
                    ...item,
                    newCustomerPartNumber: action.payload.partNumber,
                    customerPartNumber: "",
                  }
                : item;
            }),
          };
        }
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentShoppingCart.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCurrentShoppingCart.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentShoppingCart = action.payload;
    });
    builder.addCase(fetchCurrentShoppingCart.rejected, (state) => {
      state.isLoading = false;
      state.currentShoppingCart = undefined;
    });
    builder.addCase(fetchCartSummary.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCartSummary.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentCartSummary = action.payload;
    });
    builder.addCase(fetchCartSummary.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchProductInstructions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProductInstructions.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchProductInstructions.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addPrimeStockToCart.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addPrimeStockToCart.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addPrimeStockToCart.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updatePrimeStockCartItem.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updatePrimeStockCartItem.fulfilled, (state, action) => {
      if (!state.currentShoppingCart) return;
      state.currentShoppingCart.sites = state.currentShoppingCart?.sites.map(
        (site) => {
          return {
            ...site,
            items: site.items.map((item) => {
              return item.id === action.payload.id
                ? { ...item, ...action.payload }
                : item;
            }),
          };
        }
      );
      state.isLoading = false;
    });
    builder.addCase(updatePrimeStockCartItem.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(removePrimeStockFromCart.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removePrimeStockFromCart.fulfilled, (state, action) => {
      if (!state.currentShoppingCart) return;
      state.currentShoppingCart.sites = state.currentShoppingCart?.sites.map(
        (site) => {
          return {
            ...site,
            items: site.items.filter(
              (item) => item.id !== action.payload.salesOrderItemId
            ),
          };
        }
      );
      state.isLoading = false;
    });
    builder.addCase(removePrimeStockFromCart.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(addRollingItemToCart.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(addRollingItemToCart.fulfilled, (state) => {
      state.status = "success";
    });
    builder.addCase(addRollingItemToCart.rejected, (state) => {
      state.status = "error";
    });

    builder.addCase(updateRollingCartItem.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateRollingCartItem.fulfilled, (state) => {
      state.status = "success";
    });
    builder.addCase(updateRollingCartItem.rejected, (state) => {
      state.status = "error";
    });

    builder.addCase(removeRollingItemFromCart.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(removeRollingItemFromCart.fulfilled, (state) => {
      state.status = "success";
    });
    builder.addCase(removeRollingItemFromCart.rejected, (state) => {
      state.status = "error";
    });

    builder.addCase(fetchOpenCarts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOpenCarts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.openCarts = action.payload;
    });
    builder.addCase(fetchOpenCarts.rejected, (state) => {
      state.isLoading = false;
      state.openCarts = undefined;
    });
    builder.addCase(clearCustomerCart.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(clearCustomerCart.fulfilled, (state, action) => {
      state.isLoading = false;
      if (state.currentShoppingCart?.billingCustomerId === action.payload) {
        state.currentShoppingCart = undefined;
      }
      state.openCarts = state.openCarts?.filter(
        (cart) => cart.customerId !== action.payload
      );
    });
    builder.addCase(clearCustomerCart.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(changeShipTo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeShipTo.fulfilled, (state, action) => {
      state.currentShipTo = action.payload;
      state.isLoading = false;
    });
    builder.addCase(changeShipTo.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateSecondaryStockCartItem.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateSecondaryStockCartItem.fulfilled, (state) => {
      state.status = "success";
    });
    builder.addCase(updateSecondaryStockCartItem.rejected, (state) => {
      state.status = "error";
    });
    builder.addCase(updateShortsStockCartItem.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(updateShortsStockCartItem.fulfilled, (state) => {
      state.status = "success";
    });
    builder.addCase(updateShortsStockCartItem.rejected, (state) => {
      state.status = "error";
    });
    builder.addCase(removeSecondaryCartItem.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(removeSecondaryCartItem.fulfilled, (state) => {
      state.status = "success";
    });
    builder.addCase(removeSecondaryCartItem.rejected, (state) => {
      state.status = "error";
    });
    builder.addCase(removeShortsCartItem.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removeShortsCartItem.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(removeShortsCartItem.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(removeCartItem.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removeCartItem.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentShoppingCart = action.payload;
    });
    builder.addCase(removeCartItem.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateItemInstructions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateItemInstructions.fulfilled, (state, action) => {
      if (!state.currentShoppingCart) return;
      state.isLoading = false;
      state.currentShoppingCart = action.payload;
    });
    builder.addCase(updateItemInstructions.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addShortsToCart.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(addShortsToCart.fulfilled, (state) => {
      state.status = "success";
    });
    builder.addCase(addShortsToCart.rejected, (state) => {
      state.status = "error";
    });
    builder.addCase(addSecondaryToCart.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(addSecondaryToCart.fulfilled, (state) => {
      state.status = "success";
    });
    builder.addCase(addSecondaryToCart.rejected, (state) => {
      state.status = "error";
    });
    builder.addCase(validateCart.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(validateCart.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentShoppingCart = action.payload;
    });
    builder.addCase(validateCart.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(submitCart.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(submitCart.fulfilled, (state, action) => {
      state.isLoading = false;

      if (!state.currentShoppingCart) return;
      
      state.currentShoppingCart = 
      {
        ...state.currentShoppingCart,
        orderSubmitted: action.payload.orderSubmitted,
        errors: action.payload.errors,
        warnings: action.payload.warnings,
        valid: action.payload.valid,
        orderStatus: action.payload.orderStatus,
        orderDate: action.payload.orderDate,
        orderNumbers: action.payload.orderNumbers,
      }
    });
    builder.addCase(submitCart.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateCartItemDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateCartItemDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentShoppingCart = action.payload;
    });
    builder.addCase(updateCartItemDetails.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateItemPrice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateItemPrice.fulfilled, (state, action) => {
      if (!state.currentShoppingCart) return;
      state.isLoading = false;
      state.currentShoppingCart = action.payload;
    });
    builder.addCase(updateItemPrice.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const selectCurrentShoppingCart = (state: RootState) =>
  state.shoppingCart.currentShoppingCart;

export const selectCartSummary = (state: RootState) =>
  state.shoppingCart.currentCartSummary;

export default shoppingCartSlice.reducer;
export const {
  updateCustomerPONumber,
  updatePoLineNumber,
  updateItemPoNumber,
  updateItemDueDate,
  clearPoNumberFromCart,
  updateAllDueDates,
  updateCartNotes,
  updatePrePayAndAdd,
  updateDocType,
  updateCustomerPartNumber,
  decrementQuoteCartItemCount,
  clearShoppingCart,
  updateNewCustomerPartNumber,
} = shoppingCartSlice.actions;
