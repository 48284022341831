/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { numberFormatter } from "../../../../../../helpers/numberFormat";
import { CartItem } from "../../../../../../types/types";
import { useAppSelector } from "../../../../../../app/hooks";
import tw from "twin.macro";
import { selectDefaultPriceUnit } from "../../../../../../store/user.reducer";
import { currencyFormatter } from "../../../../../../helpers/currencyFormatter";
import { formatDateToShort } from "../../../../../../helpers/dateHelpers";

type Props = {
  children?: ReactNode;
  item: CartItem;
  hasCustomerPoNumber: boolean;
  hasItemWithPartList: boolean;
  usePoLineNumber: boolean;
  currency: string;
};

const ReviewShoppingItem: React.FC<Props> = ({
  item,
  hasCustomerPoNumber,
  hasItemWithPartList,
  usePoLineNumber,
  currency
}) => {
  const defaultPriceUnit = useAppSelector(selectDefaultPriceUnit);

  const price =
    defaultPriceUnit === "CFT"
      ? `${currencyFormatter(item.totalPriceCft)} CFT`
      : `${currencyFormatter(item.totalPriceCwt)} CWT`;

  const showOverridePrice = item.priceUnit ? (defaultPriceUnit !== item.priceUnit) : false;

  return (
    <>
      <tr>
        <td>{item.description}</td>
        <td>{item.grade}</td>
        <td>{numberFormatter(item.pieces)}</td>
        <td>{numberFormatter(item.weight)}</td>
        <td css={tw`text-right`}>
          {price} {currency}
          {showOverridePrice && (<div>{currencyFormatter(item.totalPrice)} {item.priceUnit}</div>)}
        </td>
        <td
          css={
            (item.source === "Secondary" || item.source === "Shorts") &&
            tw`text-red-600`
          }
        >
          {item.source}
        </td>
        {hasCustomerPoNumber ? <td>{item.customerPONumber}</td> : null}
        {usePoLineNumber && <td>{item.poLineNumber}</td>}
        <td>{formatDateToShort(item.requestedShippingDate)}</td>
        {hasItemWithPartList && (
          <td>{item.customerPartNumber ?? item.newCustomerPartNumber}</td>
        )}
      </tr>
      <tr>
        <td css={{ verticalAlign: "top" }} colSpan={4}>
          <p>Item Instructions:</p>
          {item.lengthNote && <p css={tw`text-black`}>{item.lengthNote}</p>}
          {item.notes && <p css={tw`text-black`}>{item.notes}</p>}
        </td>
        <td css={{ verticalAlign: "top" }} colSpan={100}>
          <p>Charge Lines:</p>
          {item.charges.map((item, index) => (
            <p key={index}>
              {`${item.chargeCode} ${currencyFormatter(item.calculatedAmount)} ${currency}`}
            </p>
          ))}
        </td>
      </tr>
    </>
  );
};

export default ReviewShoppingItem;
