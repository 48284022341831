/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  changeShipTo,
  clearCustomerCart,
  fetchCurrentShoppingCart,
  refreshCart,
  updateCartItemDetails,
  validateCart,
} from "../../../../../store/shoppingCart.actions";
import {
  updateAllDueDates,
  updateCustomerPONumber,
} from "../../../../../store/shoppingCart.reducer";
import {
  DateString,
  ErrorResponse,
  SalesOrderItemInput,
} from "../../../../../types/types";
import Button from "../../../../../components/atoms/Button";
import Input from "../../../../../components/atoms/Input";
import ConfirmationDialog from "../../../../../components/molecules/ConfirmationDialog";
import ChangeDueDates from "../components/ChangeDueDates";
import ChangeShipToDialog from "../components/ChangeShipToDialog";
import OpenShoppingCartsDialog from "./OpenShoppingCartsDialog";
import OrderDetails from "./components/OrderDetails";
import OrderSite from "./components/OrderSite";
import ShipTo from "../components/ShipTo";
import SoldTo from "../components/SoldTo";
import { selectCurrentCustomerId } from "../../../../../store/customer.reducer";
import Loading from "../../../../../components/atoms/Loading";
import TextButton from "../../../../../components/atoms/TextButton";
import { useNavigate } from "react-router-dom";
import { updateDefaultPriceUnit } from "../../../../../store/user.actions";
import { selectDefaultPriceUnit } from "../../../../../store/user.reducer";
import { submitCart } from "../../../../../store/shoppingCart.actions";
import { sendShoppingCartAnalytics } from "../../../../../store/analytics.actions";
import Toast from "../../../../../components/molecules/Toast";
import Disclaimer from "./components/Disclaimer";
import { isErrorResponse } from "../../../../../types/predicates";
import { stringToBase64 } from "../../../../../helpers/stringToBase64";

type Props = {
  children?: ReactNode;
};

const ShoppingCart: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { currentShoppingCart, isLoading } = useAppSelector(
    (state) => state.shoppingCart
  );

  const defaultPriceUnit = useAppSelector(selectDefaultPriceUnit);
  const customerId = useAppSelector(selectCurrentCustomerId);
  const [cartErrors, setCartErrors] = useState<string | ErrorResponse>();
  const [submitErrors, setSubmitErrors] = useState<string[]>();
  const [showChangeDueDate, setShowChangeDueDate] = useState(false);
  const [showOpenCarts, setShowOpenCarts] = useState(false);
  const [showClearCartDialog, setShowClearCartDialog] = useState(false);
  const [showChangeShipTo, setShowChangeShipTo] = useState(false);
  const [poAtLineLevel, setPoAtLineLevel] = useState(
    currentShoppingCart?.poAtLineLevel ?? false
  );
  const login = useAppSelector(
    (state) => state.user.currentContext?.user.email
  );
  const [status, setStatus] = useState<
    | "idle"
    | "submitting"
    | "validating"
    | "saving"
  >("idle");
  const poNumberRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!customerId) return;
    dispatch(fetchCurrentShoppingCart(customerId));
  }, [customerId]);

  useEffect(() => {
    if (currentShoppingCart) {
      setPoAtLineLevel(currentShoppingCart.poAtLineLevel);
    }
  }, [currentShoppingCart?.poAtLineLevel]);

  useEffect(() => {
    if (!poNumberRef.current) return;
    poNumberRef.current.value = currentShoppingCart?.customerPONumber ?? "";
  }, [poNumberRef, currentShoppingCart?.customerPONumber]);

  if (!currentShoppingCart || currentShoppingCart.empty) {
    return (
      <div css={tw`w-full text-left p-4 relative min-h-[300px]`}>
        {isLoading ? (
          <Loading css={tw`bg-white`} />
        ) : (
          <>
            <h2 css={tw`text-red-600 text-lg font-bold flex-1`}>
              Cart Details
            </h2>
            <p css={tw`text-xs p-4`}>
              There are no items in the shopping cart.
            </p>
          </>
        )}
      </div>
    );
  }

  const updateDefaultPriceUnitHandler = () => {
    const current = defaultPriceUnit ?? "CWT";
    dispatch(
      updateDefaultPriceUnit({
        defaultPriceUnit: current === "CWT" ? "CFT" : "CWT",
      })
    );
  };

  const reviewOrderHandler = () => {
    if (!customerId) return;
    setStatus("validating");
    // Clears input for unneeded field before sending data to API
    // dispatch(clearPoNumberFromCart(poType === "entire" ? "items" : "cart"));

    const itemsArr = currentShoppingCart.sites.reduce((acc, site) => {
      const items = site.items.map((item) => {
        return {
          id: item.id,
          poLineNumber: item.poLineNumber,
          requestedShippingDate: item.requestedShippingDate,
          customerPartNumber: item.customerPartNumber,
          newCustomerPartNumber: item.newCustomerPartNumber,
          customerPONumber: item.customerPONumber,
        } as SalesOrderItemInput;
      });
      return [...acc, ...items];
    }, [] as SalesOrderItemInput[]);

    setCartErrors(undefined);
    dispatch(
      validateCart({
        customerId: customerId,
        body: {
          customerPONumber: currentShoppingCart.customerPONumber,
          docType: currentShoppingCart.docType,
          prePayandAdd: currentShoppingCart.prePayandAdd,
          encodedNotes: stringToBase64(currentShoppingCart.notes),
          items: itemsArr,
          poAtLineLevel: poAtLineLevel,
        },
      })
    )
      .unwrap()
      .then((result) => {
        setStatus("idle");
        if (result.valid) {
          navigate(`/portal/stock/cart/shoppingreview?customer_id=${customerId}`);
        }
      })
      .catch((error) => {
        setCartErrors(error);
        setStatus("idle");
      });
  };

  const submitWithoutReviewHandler = () => {
    if (!customerId) return;
    setCartErrors(undefined);
    setStatus("submitting");

    dispatch(
      updateCartItemDetails({
        customerId,
        poAtLineLevel: poAtLineLevel,
      })
    ).unwrap()
    .then((cart) => {
      setStatus("idle");
      if (cart.valid) {
        submit();
      }
    }).catch((error) => {
      setCartErrors(error);
      setStatus("idle");
    });
  };

  const submit = () => {
    if (!customerId) return;
    setStatus("submitting");
    dispatch(submitCart(customerId))
      .unwrap()
      .then((cart) => {
        if (cart.orderSubmitted) {
          setStatus("idle");
          dispatch(
            sendShoppingCartAnalytics({
              ...cart,
              login: login as string,
            })
          );
          navigate(`/portal/stock/cart/shoppingreview?customer_id=${customerId}`, { state: cart });
        } else {
          setSubmitErrors(cart.errors);
          setStatus("idle");
        }
      })
      .catch((error) => {
        setCartErrors(error);
        setStatus("idle");
      });
  };

  const updateCartItemsHandler = async () => {
    if (!customerId) return;
    try {
      setStatus("saving");
      setCartErrors(undefined);
      await dispatch(
        updateCartItemDetails({
          customerId: customerId,
          poAtLineLevel: poAtLineLevel,
        })
      ).unwrap();
    } catch (error) {
      if (isErrorResponse(error)) {
        setCartErrors(error);
        return;
      } else {
        setCartErrors("An unknown error occurred.");
      }
    } finally {
      setStatus("idle");
    }
  };

  const docTypeFulfilled = currentShoppingCart?.useDocType
    ? Object.hasOwn(currentShoppingCart, "docType")
    : true;
  const datesFulfilled = currentShoppingCart?.sites?.every((site) =>
    site.items.every((item) => item.requestedShippingDate)
  );
  const poFulfilled = !poAtLineLevel
    ? currentShoppingCart?.customerPONumber &&
      currentShoppingCart.customerPONumber !== ""
    : currentShoppingCart?.sites?.every((site) =>
        site.items.every(
          (item) => item.customerPONumber && item.customerPONumber !== ""
        )
      );
  const poLineFulfilled = currentShoppingCart?.useItemPoLineNumber
    ? currentShoppingCart?.sites.every((site) =>
        site.items.every((item) => item.poLineNumber)
      )
    : true;
  const cartIsValid =
    datesFulfilled && poFulfilled && poLineFulfilled && docTypeFulfilled;

  return (
    <div css={tw`relative mt-2 self-start text-xs w-full px-2`}>
      {cartErrors && (
        <Toast
          type="error"
          onConfirm={() => setCartErrors(undefined)}
          message={cartErrors}
        />
      )}
      {showChangeDueDate && (
        <ChangeDueDates
          onConfirm={(newDueDate: DateString) => {
            dispatch(updateAllDueDates(newDueDate));
            setShowChangeDueDate(false);
          }}
          onCancel={() => setShowChangeDueDate(false)}
        />
      )}
      {showChangeShipTo && (
        <ChangeShipToDialog
          onConfirm={(id: string) => {
            if (!customerId) return;
            dispatch(
              changeShipTo({
                customerId: customerId,
                body: { shipToId: id },
              })
            )
              .unwrap()
              .then(() => {
                dispatch(refreshCart({ customerId: customerId }));
              })
              .catch((error) => {
                setCartErrors(error);
              })
              .finally(() => {
                setShowChangeShipTo(false);
              });
          }}
          onCancel={() => setShowChangeShipTo(false)}
        />
      )}
      {showOpenCarts && (
        <OpenShoppingCartsDialog onCancel={() => setShowOpenCarts(false)} />
      )}
      {showClearCartDialog && (
        <ConfirmationDialog
          title="Clear cart?"
          onCancel={() => setShowClearCartDialog(false)}
          onConfirm={() => {
            if (customerId) {
              dispatch(clearCustomerCart(customerId));
            }
            setShowClearCartDialog(false);
          }}
        >
          Are you sure you want to clear your shopping cart?
        </ConfirmationDialog>
      )}
      <div css={tw`flex items-center`}>
        <h2 css={tw`text-red-600 text-lg font-bold flex-1`}>Cart Details</h2>
        <div css={tw`flex gap-2 mr-2`}>
          <TextButton
            type="button"
            disabled={status !== "idle"}
            onClick={updateDefaultPriceUnitHandler}
          >
            {defaultPriceUnit === "CFT" ? "View CWT" : "View CFT"}
          </TextButton>
          <TextButton
            type="button"
            disabled={status !== "idle"}
            onClick={() => setShowClearCartDialog(true)}
          >
            Clear Shopping Cart
          </TextButton>
          <TextButton
            disabled={status !== "idle"}
            type="button"
            onClick={() => setShowOpenCarts(true)}
          >
            View Open Shopping Cart
          </TextButton>
        </div>
      </div>
      <form css={tw`relative mt-2`}>
        {status !== "idle" && (
          <Loading><span className="capitalize">{status}</span></Loading>
        )}
        {(currentShoppingCart?.errors.length > 0 ||
          currentShoppingCart?.warnings.length > 0) && (
          <>
            <p>
              Please resolve the following before submitting the shopping cart:
            </p>
            <ul css={tw`list-disc list-outside mx-6 mt-2 mb-3`}>
              {currentShoppingCart?.errors?.map((error, index) => (
                <pre css={tw`whitespace-pre-wrap`} key={index}>
                  <li css={tw`text-red-600`}>{error}</li>
                </pre>
              ))}
              {currentShoppingCart?.warnings?.map((warning, index) => (
                <pre css={tw`whitespace-pre-wrap`} key={index}>
                  <li css={tw`text-nucor-green`}>{warning}</li>
                </pre>
              ))}
            </ul>
          </>
        )}
        {submitErrors && (
          <>
            <p>
              Please resolve the following before submitting the shopping cart:
            </p>
            <ul css={tw`list-disc list-outside mx-6 mt-2 mb-3`}>
              {submitErrors?.map((error, index) => (
                <pre css={tw`whitespace-pre-wrap`} key={index}>
                  <li css={tw`text-red-600`}>{error}</li>
                </pre>
              ))}
            </ul>
          </>
        )}
        <div css={tw`flex gap-3`}>
          <p css={tw`font-bold`}>Enter PO Number for:</p>
          <fieldset css={tw`flex items-center cursor-pointer`}>
            <input
              checked={!poAtLineLevel}
              type="radio"
              value="entire"
              id="entire"
              onChange={() => setPoAtLineLevel(false)}
              css={tw`cursor-pointer`}
            />
            <label htmlFor="entire" css={tw`ml-[2px] cursor-pointer`}>
              Entire Order
            </label>
          </fieldset>
          <fieldset css={tw`flex items-center`}>
            <input
              checked={poAtLineLevel}
              type="radio"
              value="byitem"
              id="byitem"
              onChange={() => setPoAtLineLevel(true)}
              css={tw`cursor-pointer`}
            />
            <label htmlFor="byitem" css={tw`ml-[2px] cursor-pointer`}>
              By Item
            </label>
          </fieldset>
        </div>
        <div
          css={tw`bg-gradient-to-b from-[#e6e6e6] via-[#fdfdfd] to-[#e6e6e6] px-2 py-1 flex gap-2 my-1 items-center`}
        >
          {!poAtLineLevel && (
            <fieldset>
              <label css={tw`text-nucor-gray font-semibold`}>
                Purchase Order: <span css={tw`text-red-600`}>*</span>
              </label>
              <Input
                ref={poNumberRef}
                defaultValue={currentShoppingCart?.customerPONumber}
                onChange={(e) => dispatch(updateCustomerPONumber(e.target.value))}
                css={[tw`ml-2`, !poAtLineLevel && tw`bg-nucor-yellow`]}
              />
            </fieldset>
          )}
          <Button
            type="button"
            disabled={status !== "idle"}
            onClick={updateCartItemsHandler}
            css={tw`m-0 text-xs font-normal px-2 py-[3px]`}
          >
            Save Order
          </Button>
          <Button
            type="button"
            css={tw`m-0 text-xs font-normal px-2 py-[3px]`}
            disabled={!cartIsValid || status !== "idle"}
            onClick={reviewOrderHandler}
          >
            Review Order
          </Button>
          <Button
            type="button"
            css={tw`m-0 text-xs font-normal px-2 py-[3px]`}
            disabled={!cartIsValid || status !== "idle"}
            onClick={submitWithoutReviewHandler}
          >
            Submit Without Review
          </Button>
          <div css={tw`flex-1 text-right flex gap-2 justify-end`}>
            <TextButton
              disabled={status !== "idle"}
              type="button"
              onClick={() => setShowChangeShipTo(true)}
            >
              Change Ship To
            </TextButton>
            <TextButton
              disabled={status !== "idle"}
              type="button"
              onClick={() => setShowChangeDueDate(true)}
            >
              Change All Due Dates
            </TextButton>
          </div>
        </div>
        <p css={tw`italic text-nucor-gray my-1`}>
          <span css={tw`mx-2 text-red-600`}>*</span>indicates required fields
        </p>
        <div css={tw`bg-[#e6e6e6] flex justify-evenly items-start p-1 mt-2`}>
          <div css={tw`w-full`}>
            <SoldTo customer={currentShoppingCart?.billingCustomer} />
          </div>
          <div css={tw`w-full`}>
            <ShipTo customer={currentShoppingCart?.shipTo} />
          </div>
          <div css={tw`w-full`}>
            <OrderDetails cart={currentShoppingCart} />
          </div>
        </div>
        <div>
          {currentShoppingCart?.sites.map((site, index) =>
            site.items.length > 0 ? (
              <OrderSite
                saveCart={updateCartItemsHandler}
                poRequired={poAtLineLevel}
                site={site}
                key={index}
              />
            ) : null
          )}
        </div>
      </form>
      <Disclaimer />
    </div>
  );
};

export default ShoppingCart;
