/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import {
  dateToString,
  stringToDate,
} from "../../../../../../helpers/dateHelpers";
import { removeCartItem } from "../../../../../../store/shoppingCart.actions";
import {
  updateCustomerPartNumber,
  updateItemDueDate,
  updateItemPoNumber,
  updateNewCustomerPartNumber,
  updatePoLineNumber,
} from "../../../../../../store/shoppingCart.reducer";
import { CartItem } from "../../../../../../types/types";
import DatePicker from "../../../../../../components/atoms/DatePicker";
import Input from "../../../../../../components/atoms/Input";
import SpecialInstructions from "./SpecialInstructions";
import { selectCurrentCustomerId } from "../../../../../../store/customer.reducer";
import { setPrimeStockSelections } from "../../../../../../store/product.reducer";
import { useNavigate } from "react-router-dom";
import Select from "../../../../../../components/atoms/Select";
import { numberFormatter } from "../../../../../../helpers/numberFormat";
import {
  selectCurrentAuthorities,
  selectDefaultPriceUnit,
} from "../../../../../../store/user.reducer";
import { setRollingItemToEdit } from "../../../../../../store/rollings.reducer";
import { currencyFormatter } from "../../../../../../helpers/currencyFormatter";
import CustomerPartNumberDialog from "../../components/CustomerPartNumberDialog";
import ChangePriceDialog from "../../components/ChangePriceDialog";
import TextButton from "../../../../../../components/atoms/TextButton";
import { hasAuthority } from "../../../../../../helpers/hasAuthority";

type Props = {
  children?: ReactNode;
  item: CartItem;
  poRequired?: boolean;
  saveCart: () => Promise<void>;
};

const LineItem: React.FC<Props> = ({ item, poRequired, saveCart }) => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const defaultPriceUnit = useAppSelector(selectDefaultPriceUnit);
  const usePoLineNumber = useAppSelector(
    (state) => state.customer.currentCustomer?.useItemPoLineNumber
  );
  const hasItemWithPartList = useAppSelector(
    (state) => state.shoppingCart.currentShoppingCart?.hasItemWithPartList
  );

  const { currentShoppingCart } = useAppSelector((state) => state.shoppingCart);

  const [showSpecialInstructions, setShowSpecialInstructions] = useState(false);
  const [showCustomerPartDialog, setShowCustomerPartDialog] = useState(false);
  const [showChangePriceDialog, setShowChangePriceDialog] = useState(false);

  const itemPoRef = useRef<HTMLInputElement>(null);
  const itemPoLineRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const isInternal = hasAuthority("internal", currentAuthorities);

  const removeItemHandler = () => {
    if (!customerId) return;
    const cartItem = {
      customerId,
      salesOrderItemId: item.id,
    };
    dispatch(removeCartItem(cartItem));
  };

  const editHandler = () => {
    dispatch(setPrimeStockSelections(item.productIndex));
    if (item.source === "Rolling") {
      dispatch(setRollingItemToEdit(item.id));
    }
    navigate(`/portal/stock/stocklist?customer_id=${customerId}`);
  };

  const showSpecialInstructionsHandler = async () => {
    await saveCart();
    setShowSpecialInstructions(true);
  };

  const showChangePriceHandler = async () => {
    await saveCart();
    setShowChangePriceDialog(true);
  };

  const updateNewCustomerPartNumberHandler = (value: string) => {
    dispatch(updateNewCustomerPartNumber({ id: item.id, partNumber: value }));
    setShowCustomerPartDialog(false);
  };

  const itemPrice =
    defaultPriceUnit === "CFT"
      ? `${currencyFormatter(item.totalPriceCft)} CFT`
      : `${currencyFormatter(item.totalPriceCwt)} CWT`;

  const priceSheetPrice = item.priceSheetPriceDisplay;

  const showOverridePrice = item.priceUnit ? (defaultPriceUnit !== item.priceUnit) : false;
  
  const customerPartNumberList = item.parts?.map((part) => ({
    value: part.customerPartNumber,
    label: (
      <span>
        {part.customerPartNumber}
        <span css={tw`ml-4`}>{part.customerPartDescription}</span>
      </span>
    ),
    selectedLabel: part.customerPartNumber,
  }));

  useEffect(() => {
    if (itemPoLineRef.current) {
      itemPoLineRef.current.value = item.poLineNumber?.toString() ?? "";
    }
    if (itemPoRef.current) {
      itemPoRef.current.value = item.customerPONumber ?? "";
    }
  }, [itemPoRef, itemPoLineRef, item.poLineNumber, item.customerPONumber]);

  return (
    <>
      {showCustomerPartDialog && (
        <CustomerPartNumberDialog
          defaultValue={item.newCustomerPartNumber}
          onCancel={() => setShowCustomerPartDialog(false)}
          onConfirm={updateNewCustomerPartNumberHandler}
        />
      )}
      {showSpecialInstructions && (
        <SpecialInstructions
          onClose={() => setShowSpecialInstructions(false)}
          salesOrderItemId={item.id}
        />
      )}
      {isInternal && showChangePriceDialog && (
        <ChangePriceDialog
          onCancel={() => setShowChangePriceDialog(false)}
          customerId={currentShoppingCart?.billingCustomerId}
          salesOrderItemId={item.id}
          currentPrice={item.totalPrice}
          currentPriceUnit={item.priceUnit}
          priceSheetPrice={priceSheetPrice}
          quote={false}
        />
      )}

      <tr>
        <td className="relative">
          <span className="peer">{item.description}</span>
          {isInternal && (
            <span
              css={tw`absolute bg-white px-2 py-1 rounded border right-2 -top-4 hidden peer-hover:block`}
            >{`${item.itemNumber} ${item.inventoryStatus}`}</span>
          )}
        </td>
        <td>{item.grade}</td>
        <td css={tw`text-right`}>{item.pieces}</td>
        <td css={tw`text-right`}>{numberFormatter(item.weight)}</td>
        <td css={tw`text-right`}>
          {isInternal && (
            <>
            <TextButton css={tw`ml-4`} onClick={() => showChangePriceHandler()}>
              {itemPrice} {currentShoppingCart?.currency}  
            </TextButton>
            {showOverridePrice && (<div>{currencyFormatter(item.totalPrice)} {item.priceUnit}</div>)}
            </>
          )}
          {!isInternal && (
            <span>{itemPrice} {currentShoppingCart?.currency}</span>
          )}
        </td>
        <td
          css={
            (item.source === "Secondary" || item.source === "Shorts") &&
            tw`text-red-600`
          }
        >
          {item.source}
        </td>
        {poRequired && (
          <td>
            <Input
              css={poRequired && tw`bg-nucor-yellow`}
              defaultValue={item.customerPONumber}
              ref={itemPoRef}
              onChange={(e) =>
                dispatch(
                  updateItemPoNumber({ id: item.id, po: e.target.value })
                )
              }
            />
          </td>
        )}
        {usePoLineNumber && (
          <td css={tw`text-center w-[12ch]`}>
            <input
              ref={itemPoLineRef}
              type="number"
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (
                  ["Tab", "ArrowLeft", "ArrowRight", "Backspace"].includes(
                    e.key
                  )
                )
                  return;
                if (e.key === "Escape" || e.key === "Enter")
                  return e.currentTarget.blur();
                if (!/[0-9]+/.test(e.key)) return e.preventDefault();
              }}
              min={1}
              css={tw`w-[5ch] bg-nucor-yellow`}
              defaultValue={item.poLineNumber}
              onChange={(e) => {
                dispatch(
                  updatePoLineNumber({
                    id: item.id,
                    lineNumber:
                      e.target.value === "" ? undefined : +e.target.value,
                  })
                );
              }}
            />
          </td>
        )}
        <td css={tw`w-[20ch] text-center`}>
          <DatePicker
            hidePrevious
            highlight
            name="date"
            maxDate={stringToDate(item.maxDueDate)}
            minDate={stringToDate(item.minDueDate)}
            value={stringToDate(item.requestedShippingDate)}
            onChange={(date) => {
              dispatch(
                updateItemDueDate({ id: item.id, date: dateToString(date) })
              );
            }}
            hasError={true}
            
          />
        </td>
        {hasItemWithPartList && (
          <td>
            {!item.newCustomerPartNumber && item.parts.length > 0 && (
              <Select
                minWidth="15ch"
                value={item.customerPartNumber ?? ""}
                onChange={(value: string) => {
                  dispatch(
                    updateCustomerPartNumber({
                      id: item.id,
                      customerPartNumber: value,
                    })
                  );
                }}
                name="partList"
                data={[
                  { value: "", label: <span>None</span> },
                  ...customerPartNumberList,
                ]}
              />
            )}
            {!item.customerPartNumber && (
              <TextButton
                css={
                  !item.newCustomerPartNumber &&
                  item.parts.length > 0 &&
                  tw`ml-4`
                }
                onClick={() => setShowCustomerPartDialog(true)}
              >
                {item.newCustomerPartNumber
                  ? item.newCustomerPartNumber
                  : "New Part"}
              </TextButton>
            )}
          </td>
        )}
        <td css={tw``}>
          {item.allowEdit && (
            <TextButton onClick={editHandler} css={tw`mx-2`}>
              Edit
            </TextButton>
          )}
          <TextButton onClick={removeItemHandler}>
            Remove
          </TextButton>
        </td>
      </tr>
      <tr>
        <td valign="top" colSpan={100}>
          <div css={tw`flex justify-between items-start`}>
            <div css={tw`max-w-[550px]`}>
              <p css={tw`text-nucor-gray`}>Item Instructions:</p>
              {item.lengthNote && <p>{item.lengthNote}</p>}
              {item.notes && <p>{item.notes}</p>}
            </div>
            {item.allowProductInstructions && (
              <button
                onClick={showSpecialInstructionsHandler}
                type="button"
                css={tw`text-red-600 font-bold underline mr-2 focus-visible:text-nucor-link-hover focus-visible:outline-none`}
              >
                Special Instructions
              </button>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default LineItem;
