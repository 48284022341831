/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import SortDirection from "../../../../components/molecules/SortDirection";
import { InvoiceDocumentPagedResult } from "../../../../types/types";
import TextButton from "../../../../components/atoms/TextButton";
import {
  exportPackingSlipPDF,
  ExportPackingSlipPDFRequest,
  fetchInvoiceDocumentDetailPDF,
  FetchInvoiceDocumentDetailPDFRequest,
  fetchOrderDocumentDetailPDF,
  FetchOrderDocumentDetailPDFRequest,
} from "../../../../store/document.actions";
import { invoiceSort } from "../../../../store/document.reducer";
import Toast from "../../../../components/molecules/Toast";
import { formatDateToShort } from "../../../../helpers/dateHelpers";
import { currencyFormatter } from "../../../../helpers/currencyFormatter";
import { hasAuthority } from "../../../../helpers/hasAuthority";
import { selectCurrentAuthorities } from "../../../../store/user.reducer";
import { PDFContext } from "./InvoicesList";

type Props = {
  children?: ReactNode;
  invoices?: InvoiceDocumentPagedResult;
};

const InvoicesResults: React.FC<Props> = ({ invoices }) => {
  const dispatch = useAppDispatch();
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const { searchCriteria } = useAppSelector((state) => state.document.invoices);
  const [psStatus, setPsStatus] = useState("idle");
  const [invoiceStatus, setInvoiceStatus] = useState("idle");
  const [orderStatus, setOrderStatus] = useState("idle");
  const [errorMessage, setErrorMessage] = useState("");
  const ctx = useContext(PDFContext);

  const packingSlipPDFHandler = (params: ExportPackingSlipPDFRequest) => {
    setPsStatus("pending");
    setErrorMessage("");
    dispatch(exportPackingSlipPDF(params))
      .unwrap()
      .then((result) => {
        ctx?.setFile?.(result);
        setPsStatus("success");
      })
      .catch((error) => {
        setPsStatus("error");
        setErrorMessage(error);
      });
  };

  const invoicePDFHandler = (params: FetchInvoiceDocumentDetailPDFRequest) => {
    setInvoiceStatus("pending");
    setErrorMessage("");
    dispatch(fetchInvoiceDocumentDetailPDF(params))
      .unwrap()
      .then((result) => {
        ctx?.setFile?.(result);
        setInvoiceStatus("success");
      })
      .catch((error) => {
        setInvoiceStatus("error");
        setErrorMessage(error);
      });
  };

  const viewOrderHandler = (params: FetchOrderDocumentDetailPDFRequest) => {
    setOrderStatus("pending");
    setErrorMessage("");
    dispatch(fetchOrderDocumentDetailPDF(params))
      .unwrap()
      .then((result) => {
        setOrderStatus("success");
        ctx?.setFile?.(result);
      })
      .catch((error) => {
        setOrderStatus("error");
        setErrorMessage(error);
      });
  };

  return (
    <>
      {errorMessage && (
        <Toast
          type="error"
          message={errorMessage}
          onConfirm={() => setErrorMessage("")}
        />
      )}
      <table className="ntp-portal-table" css={tw`w-full text-xs`}>
        <thead>
          <tr>
            <th>
              <button onClick={() => dispatch(invoiceSort("invoiceDate"))}>
                Invoice Date
                {searchCriteria.sort === "invoiceDate" && (
                  <SortDirection dir={searchCriteria.dir} />
                )}
              </button>
            </th>
            <th>
              <button
                onClick={() => dispatch(invoiceSort("billingCustomerId"))}
              >
                Customer
                {searchCriteria.sort === "billingCustomerId" && (
                  <SortDirection dir={searchCriteria.dir} />
                )}
              </button>
            </th>
            <th>
              <button onClick={() => dispatch(invoiceSort("invoiceNumber"))}>
                Invoice #
                {searchCriteria.sort === "invoiceNumber" && (
                  <SortDirection dir={searchCriteria.dir} />
                )}
              </button>
            </th>
            <th>Amount</th>
            <th>Balance</th>
            <th>Mill</th>
            <th>PO Number</th>
            <th>Bill of Lading</th>
            <th>Order #</th>
          </tr>
        </thead>
        <tbody>
          {invoices?.list && invoices.list.length > 0 ? (
            invoices?.list.map((invoice, index) => (
              <tr key={index}>
                <td>
                  {formatDateToShort(invoice.invoiceDate)}
                </td>
                <td css={tw`max-w-[350px]`}>{`${invoice.billingCustomerId} - ${invoice.customerName}`}</td>
                <td>
                  <TextButton
                    disabled={invoiceStatus === "pending"}
                    onClick={() => {
                      invoicePDFHandler({
                        customerId: invoice.billingCustomerId,
                        id: invoice.invoiceNumber,
                      });
                    }}
                    key={index}
                  >
                    {invoice.invoiceNumber}
                  </TextButton>
                </td>
                <td css={tw`text-right`}>{currencyFormatter(invoice.totalAmount)} {invoice.currencyDisplay}</td>
                <td css={tw`text-right`}>
                  {(invoice.balance && invoice.balance != 0) ? (<>{currencyFormatter(invoice.balance)} {invoice.currencyDisplay}</>) : ""}
                </td>
                <td>{invoice.site}</td>
                <td>
                  {invoice.customerPONumberList?.map((item) => (
                    <p key={item}>{item}</p>
                  ))}
                </td>
                <td>
                  {invoice.bolNumberList?.map((item) => (
                    <TextButton
                      key={item}
                      disabled={
                        psStatus === "pending" ||
                        !hasAuthority("viewShippingDocs", currentAuthorities)
                      }
                      onClick={() =>
                        packingSlipPDFHandler({
                          customerId: invoice.billingCustomerId,
                          id: item,
                        })
                      }
                      css={tw`block`}
                    >
                      {item}
                    </TextButton>
                  ))}
                </td>
                <td>
                  {invoice.salesOrderNumberList?.map((item) => (
                    <TextButton
                      key={item}
                      disabled={orderStatus === "pending"}
                      onClick={() =>
                        viewOrderHandler({
                          customerId: invoice.billingCustomerId,
                          id: item,
                        })
                      }
                      css={tw`block`}
                    >
                      {item}
                    </TextButton>
                  ))}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={12}>
              No invoices found for the selected criteria
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default InvoicesResults;
