/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import tw from "twin.macro";
import { ErrorResponse, QuickQuoteLine } from '../../../../types/types';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import DatePicker from '../../../../components/atoms/DatePicker';
import LengthComponent from '../../../../components/molecules/LengthComponent';
import { inchesToLength, lengthToInches } from '../../../../helpers/lengthHelpers';
import Select from '../../../../components/atoms/Select';
import { addQuickQuoteLine, updateQuickQuoteLine, fetchQuickQuoteSetup } from '../../../../store/document.actions';
import { dateToString } from '../../../../helpers/dateHelpers';
import ComboBox from '../../../../components/atoms/ComboBox';
import Toast from '../../../../components/molecules/Toast';
import Input from '../../../../components/atoms/Input';
import { currencyFormatter } from "../../../../helpers/currencyFormatter";
import TextButton from "../../../../components/atoms/TextButton";

type Props = {
  quoteId: number;
  itemkey: number;
  line: QuickQuoteLine;
  onCancelClick: (id: number) => void;
  onSaveClick: (id: number) => void;
  currency: string | undefined;
};

  const QuoteEditLine: React.FC<Props> = ({ quoteId, itemkey, line, onCancelClick, onSaveClick, currency}) => {
    const { quickQuoteSetup } = useAppSelector((state) => state.document.quickquote);
    const [selectedItemType, setSelectedItemType] = useState(line.itemType ?? 'Item');
    const [selectedQuantityType, setSelectedQuantityType] = useState(line.quantityType);
    const [selectedQuantity, setSelectedQuantity] = useState(line.quantity);
    const [createdDate, setCreatedDate] = useState<Date | undefined>(line.customerRequestDate ? new Date(line.customerRequestDate + 'T00:00:00') : undefined);
    const [length, setLength] = useState(inchesToLength(line.length) ?? {
      feet: 0,
      inches: 0,
      fraction: 0,
    });
    const [selectedUnitPrice, setSelectedUnitPrice] = useState(line.unitPrice);
    const [selectedUnitPriceUOM, setSelectedUnitPriceUOM] = useState(line.unitPriceUOM);
    const [selectedItem, setSelectedItem] = useState(line.itemType === "Part" ? line.partNo : line.itemId);
    const [selectedItemName, setSelectedItemName] = useState(line.itemType === "Text" ? line.itemName : "");
    const dispatch = useAppDispatch();
    const [error, setError] = useState<ErrorResponse>();
    const [fieldValidationErrors, setFieldValidationErrors] = useState<ErrorResponse>();

    const [selectedPriceSheetPrice, setSelectedPriceSheetPrice] = useState<number>();
    const [selectedPriceSheetPriceUnit, setSelectedPriceSheetPriceUnit] = useState<string>();
    const [selectedCWTPrice, setSelectedCWTPrice] = useState<number>();
    const [selectedCFTPrice, setSelectedCFTPrice] = useState<number>();
    const [selectedPCPrice, setSelectedPCPrice] = useState<number>();

    useEffect(() => {
      handlePriceChange();
    }, [selectedItemType, selectedItem, selectedUnitPrice, selectedUnitPriceUOM, length]);

    const priceSheetHandler = () => {
      if (selectedPriceSheetPrice) {
        setSelectedUnitPrice(selectedPriceSheetPrice);
      }
      if (selectedPriceSheetPriceUnit) {
        setSelectedUnitPriceUOM(selectedPriceSheetPriceUnit);
      }
    };

    const cwtHandler = () => {
      if (selectedCWTPrice) {
        setSelectedUnitPrice(selectedCWTPrice);
        setSelectedUnitPriceUOM("CWT");
      }      
    };

    const cftHandler = () => {
      if (selectedCFTPrice) {
        setSelectedUnitPrice(selectedCFTPrice);
        setSelectedUnitPriceUOM("CFT");
      }      
    };
    
    const pcHandler = () => {
      if (selectedPCPrice) {
        setSelectedUnitPrice(selectedPCPrice);
        setSelectedUnitPriceUOM("PC");
      }      
    };

    const handlePriceChange = () => {
      
      const price = selectedUnitPrice;
      const priceUnit = selectedUnitPriceUOM;

      let weightPerFoot;
      let priceSheetPrice;
      let priceSheetPriceUnit;

      let cwtPrice;
      let cftPrice;
      let pcPrice;
      
      if (selectedItemType === 'Item' && selectedItem) {
        const product = quickQuoteSetup?.products.find((item) => selectedItem === item.itemNumber);
        if (product) {
          const itemPriceDetails = quickQuoteSetup?.itemNumberPriceMap[selectedItem];
          if (itemPriceDetails) {
            priceSheetPrice = itemPriceDetails.price;
            priceSheetPriceUnit = itemPriceDetails.priceUnit;
          }
          weightPerFoot = product.weightPerFoot;
        }
      } else if (selectedItemType === 'Part' && selectedItem) {
        const part = quickQuoteSetup?.parts.find((item) => item.customerPartNumber === selectedItem);
        if (part) {
          const partPriceDetails = quickQuoteSetup?.partNumberPriceMap[selectedItem];
          if (partPriceDetails) {
            priceSheetPrice = partPriceDetails.price;
            priceSheetPriceUnit = partPriceDetails.priceUnit;
          }
          weightPerFoot = part.weightPerFoot;
        }
      }

      const itemLength = lengthToInches(length) || 0;

      if ("CWT" === priceUnit && price > 0) {
        cwtPrice = Math.round(price * 100) / 100;
      } else if ("CFT" === priceUnit && price > 0) {
        cftPrice = Math.round(price * 100) / 100;
      } else if ("PC" === priceUnit && price > 0 && itemLength > 0) {
        pcPrice = Math.round(price * 100) / 100;
      }

      if (weightPerFoot) {
        if ("CWT" === priceUnit && cwtPrice) {
          cftPrice = weightPerFoot * cwtPrice;
          cftPrice = Math.round(cftPrice * 100) / 100;

          if (itemLength > 0) {
            pcPrice = ((itemLength / 12) * weightPerFoot * cwtPrice) / 100;
            pcPrice = Math.round(pcPrice * 100) / 100;
          }
        } else if ("CFT" === priceUnit && cftPrice) {
          cwtPrice = price / weightPerFoot;
          cwtPrice = Math.round(cwtPrice * 100) / 100;

          if (itemLength > 0) {
            pcPrice = ((itemLength / 12) * cftPrice) / 100;
            pcPrice = Math.round(pcPrice * 100) / 100;
          }
        } else if ("PC" === priceUnit && pcPrice && itemLength > 0) {
          cwtPrice = pcPrice * 100 / ((itemLength / 12) * weightPerFoot);
          cwtPrice = Math.round(cwtPrice * 100) / 100;

          cftPrice = pcPrice * 100 / (itemLength / 12);
          cftPrice = Math.round(cftPrice * 100) / 100;
        }
      }

      setSelectedPriceSheetPrice(priceSheetPrice);
      setSelectedPriceSheetPriceUnit(priceSheetPriceUnit);

      if ("CWT" === priceUnit) {
        setSelectedCWTPrice(undefined);
        setSelectedCFTPrice(cftPrice);
        setSelectedPCPrice(pcPrice);
      } else if ("CFT" === priceUnit) {
        setSelectedCFTPrice(undefined);
        setSelectedCWTPrice(cwtPrice);
        setSelectedPCPrice(pcPrice);  
      } else if ("PC" === priceUnit) {
        setSelectedPCPrice(undefined);
        setSelectedCWTPrice(cwtPrice);
        setSelectedCFTPrice(cftPrice);
      }

    };

    const handleSaveClick = () => {
      setFieldValidationErrors(undefined);
      
      const lineData = { 
        quoteId: quoteId, 
        line: { 
          length: lengthToInches(length) || 0, 
          quantity: selectedQuantity, 
          quantityType: selectedQuantityType, 
          itemType: selectedItemType, 
          unitPrice: selectedUnitPrice, 
          unitPriceUOM: selectedUnitPriceUOM, 
          customerRequestDate: dateToString(createdDate) || "", 
          estimatedReadyDate: "", 
          itemName: selectedItemName,
          ...(selectedItemType === "Item" && { itemId: selectedItem || undefined }),
          ...(selectedItemType === "Part" && { partNo: selectedItem || undefined }),
          ...(selectedItemType === "Text" && { partNo: undefined, itemId: undefined, itemName: selectedItemName.trim()}),
        } 
      };

      // if the line.id starts with 99, it is a new line
      if (String(line.id).startsWith('99')) {
        dispatch(addQuickQuoteLine(lineData)).unwrap().then(() => {
          onSaveClick(line.id);
        });
      } else {
        dispatch(updateQuickQuoteLine({ ...lineData, lineId: line.id }))
        .unwrap()
        .then(() => {
          onSaveClick(line.id);
        })
        .then(() => {
          dispatch(fetchQuickQuoteSetup({
            quickQuoteId: quoteId,
          }))
        })
        .catch((error) => { 
          setError(error);
          setFieldValidationErrors(error);
        });
      } 
      
    };

    const updatePrice = (itemType: string, value: string) => {
      if (itemType === 'Item') {
        const itemPriceDetails = quickQuoteSetup?.itemNumberPriceMap[value];
        if (itemPriceDetails) {
          setSelectedUnitPrice(itemPriceDetails.price);
          setSelectedUnitPriceUOM(itemPriceDetails.priceUnit);
        }
      } else if (itemType === 'Part') {
        const partPriceDetails = quickQuoteSetup?.partNumberPriceMap[value];
        if (partPriceDetails) {
          setSelectedUnitPrice(partPriceDetails.price);
          setSelectedUnitPriceUOM(partPriceDetails.priceUnit);
        }
      }
    }

    return (
      <>
        <tr key={itemkey}>
          <td css={tw`text-center`}><div css={tw`mt-2`}>{line.ordinal !== 0 ? line.ordinal : ''}</div></td>
          <td css={[tw`text-xs`, fieldValidationErrors?.validations?.find(item => item.field === 'partNo' || item.field === 'itemName') && tw`border bg-red-500`]}>  
            <div css={tw`flex mt-4 ml-1 gap-2`}>
            <Select value={selectedItemType}
              name={"itemType"}
              highlight
              data={quickQuoteSetup?.itemTypes.map((type) => (
                { 
                  value: type.id,
                  label: type.description
                }
              ))} 
              onChange={(e: string) => {
                setSelectedItemType(e);
                setSelectedItemName("");
                setSelectedItem(undefined);
              }}
              minWidth='9ch'
             />   
              {selectedItemType === 'Item' && (
                <ComboBox
                  highlight
                  stringIncludes
                  onChange={(value) => {
                    setSelectedItem(value);
                    updatePrice(selectedItemType, value);
                  }}
                  value={selectedItem || ""}
                  data={quickQuoteSetup?.products.map((item) => ({
                    value: item.itemNumber,
                    label: (
                      <span>
                        {item.itemNumber} ({item.searchName}) - {item.name}
                      </span>
                    ),
                    name: item.name,
                    searchName: item.searchName,
                  }))}
                />
              )}
              {selectedItemType === 'Part' && (
                <ComboBox
                  highlight
                  stringIncludes
                  onChange={(value) => {
                      setSelectedItem(value);
                      const item = quickQuoteSetup?.parts.find((item) => item.customerPartNumber === value);
                      if (item) {
                        const length = item.length ? inchesToLength(item.length) : undefined;
                        if (length) {
                          setLength(length);
                        }
                      }
                      updatePrice(selectedItemType, value);
                    }
                  }
                  value={selectedItem || ""}
                  data={quickQuoteSetup?.parts.map((item) => ({
                    value: item.customerPartNumber,
                    label: (
                      <span>
                        {item.customerPartNumber} - {item.displayDescription}
                      </span>
                    ),
                    name: item.displayDescription,
                  }))}
                />
              )}
              {selectedItemType === 'Text' && (
                <Input
                  name='itemName' 
                  css={[tw`bg-[#eee] text-xs bg-nucor-yellow`]} 
                  type="text"
                  value={selectedItemName} 
                  onChange={(e) => setSelectedItemName(e.target.value)}
                />
              )}
            </div>
          </td>
          <td>
            <LengthComponent
              value={length}
              onLengthChange={(len) => {
                  setLength(len);
                }
              }
              css={tw`mb-1 ml-1`}
            />
          </td>
          <td css={[tw`text-xs`, fieldValidationErrors?.validations?.find(item => item.field === 'quantity') && tw`border bg-red-500`]}>
            <div css={tw`mt-4 ml-1 gap-2`}>
                <Input
                  css={tw`w-16 mr-2 bg-nucor-yellow`}
                  name="quantity" 
                  type="number" 
                  value={selectedQuantity === 0 ? "" : selectedQuantity} 
                  onChange={(e) => setSelectedQuantity(Number(e.target.value))}
                />

                <Select value={selectedQuantityType}
                  highlight
                  name={"quantityType"}
                  onChange={(e: string) => setSelectedQuantityType(e)} 
                  minWidth='9ch'
                  data={quickQuoteSetup?.quantityTypes.map((type) => (
                    { 
                      value: type.id,
                      label: type.description === "Pieces" ? "Pcs" : type.description
                    }
                  ))
                }/>
              </div>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <div css={tw`mt-4 mx-1`}>
              <DatePicker
                    css={tw`bg-white`}
                    name="dueDate"
                    value={createdDate}
                    onChange={setCreatedDate}
                />
              </div>
          </td>
          <td>
            <div css={tw`mt-4 ml-1 gap-2 flex`}>
              <Input
                  css={tw`w-16 bg-nucor-yellow`}
                  name="unitPrice"
                  type="number" 
                  value={selectedUnitPrice === 0 ? "" : selectedUnitPrice} 
                  onChange={(e) => {
                    setSelectedUnitPrice(Number(e.target.value));
                  }}
                />
                <Select
                  highlight
                  minWidth='10ch'
                  alignTo='left'
                  value={selectedUnitPriceUOM} 
                  name={"unitPriceUOM"}
                  onChange={(e: string) =>  {
                    setSelectedUnitPriceUOM(e);
                  }}
                  data={quickQuoteSetup?.priceUnits.map((types) => (
                    { 
                      value: types.id,
                      label: types.description
                    }
                  ))}
                />
                <div css={tw`mt-1 ml-1`}>{currency}</div>
              </div>
              <div>
                 {selectedPriceSheetPrice && <div css={tw`mt-1`}> Price Sheet: <TextButton onClick={priceSheetHandler}>{currencyFormatter(selectedPriceSheetPrice)} {selectedPriceSheetPriceUnit}</TextButton></div>} 
                 {selectedCWTPrice && <div css={tw`mt-1`}>CWT Price: <TextButton onClick={cwtHandler}>{currencyFormatter(selectedCWTPrice)}</TextButton></div>}
                 {selectedCFTPrice && <div css={tw`mt-1`}>CFT Price: <TextButton onClick={cftHandler}>{currencyFormatter(selectedCFTPrice)}</TextButton></div>} 
                 {selectedPCPrice && <div css={tw`mt-1`}>PC Price: <TextButton onClick={pcHandler}>{currencyFormatter(selectedPCPrice)}</TextButton></div>} 
              </div>
          </td>
          <td></td>
          <td></td>
          <td css={tw`text-center`}>
            <div css={tw`mt-5`}>
              {(selectedItemType === 'Text' && (!selectedItemName || '' === selectedItemName.trim())) || (selectedItemType !== 'Text' && !selectedItem) || selectedQuantity === 0 || selectedUnitPrice === 0 ? (
                <button 
                  css={tw`text-xs font-semibold text-nucor-gray`}
                  disabled={true}
                  >Save
                </button> 
              ) : (
                <button 
                  css={tw`text-xs font-semibold text-nucor-link underline hover:(text-nucor-link-hover no-underline) focus-visible:text-nucor-link-hover focus-visible:outline-none`}
                  onClick={handleSaveClick}
                  disabled={false}
                  >Save
                </button> 
              )}
              <button 
                css={tw`ml-2 text-xs font-semibold text-nucor-link underline hover:(text-nucor-link-hover no-underline) focus-visible:text-nucor-link-hover focus-visible:outline-none`}
                onClick={() => {
                  onCancelClick(line.id);
                }}>Cancel
              </button>
            </div>
          </td>
        </tr>
          
        {error && (
          <Toast
            type="error"
            message={error}
            onConfirm={() => setError(undefined)}
          />
        )}
      </>
    );
  };

export default QuoteEditLine;