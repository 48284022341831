/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import Input from "../../../../../components/atoms/Input";
import Button from "../../../../../components/atoms/Button";
import Select from "../../../../../components/atoms/Select";
import { PriceUnitType } from "../../../../../types/types";
import Modal from "../../../../../components/molecules/Modal";
import { ErrorResponse, RequestStatus } from "../../../../../types/types";
import { useAppDispatch } from "../../../../../app/hooks";
import { updateItemPrice } from "../../../../../store/shoppingCart.actions";
import { updateQuoteItemPrice } from "../../../../../store/quoteCart.actions";
import Loading from "../../../../../components/atoms/Loading";
import Toast from "../../../../../components/molecules/Toast";

type Props = {
  children?: ReactNode;
  onCancel: () => void;
  customerId?: string,
  salesOrderItemId: number;
  currentPrice?: number,
  currentPriceUnit?: PriceUnitType,
  priceSheetPrice?: string,
  quote: boolean
};

const CustomerPartNumberDialog: React.FC<Props> = ({
  onCancel,
  customerId,
  salesOrderItemId,
  currentPrice,
  currentPriceUnit,
  priceSheetPrice,
  quote
}) => {

  const dispatch = useAppDispatch();

  const [newPrice, setNewPrice] = useState<number>(
    currentPrice ?? 0
  );

  const [newPriceUnit, setNewPriceUnit] = useState<PriceUnitType>(
    currentPriceUnit ?? "CWT"
  );

  const [status, setStatus] = useState<RequestStatus | "updating">("idle");
  const [error, setError] = useState<ErrorResponse | string>();

  const priceSheetPriceDisplay = priceSheetPrice ? "$" + priceSheetPrice : "Not Available";

  const formHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!customerId) return;

    const price = newPrice === 0 ? undefined : newPrice;

    setStatus("updating");

    if (quote) {
      dispatch(
        updateQuoteItemPrice({
          customerId,
          salesOrderItemId,
          body: {
            totalPrice: price,
            priceUnit: newPriceUnit
          }
        })
      )
      .unwrap()
      .then(() => {
        setStatus("idle");
        onCancel();
      })
      .catch((responseError) => {
        setStatus("idle");
        setError(responseError);
      });
    } else {
      dispatch(
        updateItemPrice({
          customerId,
          salesOrderItemId,
          body: {
            totalPrice: price,
            priceUnit: newPriceUnit
          }
        })
      )
      .unwrap()
      .then(() => {
        setStatus("idle");
        onCancel();
      })
      .catch((responseError) => {
        setStatus("idle");
        setError(responseError);
      });
    }
  };

  return (
    <>
      {error && (
        <Toast
          message={error}
          type="error"
          onConfirm={() => setError(undefined)}
        />
      )}
      <Modal onCancel={onCancel} title="Update Price">
        <form onSubmit={formHandler} className="p-1 relative">
          {status === "updating" && <Loading>Updating</Loading>}
          <fieldset>
            <div className="flex items-center">
              <label css={tw`w-[80px]`}>Price Sheet:</label>
              <span>{priceSheetPriceDisplay}</span>
            </div>
            <div className="flex mt-2">
              <label css={tw`w-[80px] mt-1`}>Override:</label>
              <Input
                  css={tw`w-16 mr-2`}
                  name="quantity" 
                  type="number" 
                  value={newPrice === 0 ? "" : newPrice} 
                  onChange={(e) => setNewPrice(Number(e.target.value))}
                />

              <Select value={newPriceUnit}
                minWidth="8ch"
                highlight
                name={"quantityType"}
                onChange={(e: PriceUnitType) => setNewPriceUnit(e)} 
                data={[
                  { value: "CWT", label: "CWT" },
                  { value: "CFT", label: "CFT" },
                  { value: "PC", label: "PC" }
                ]}
              />
            </div>
          </fieldset>
          <div className="mt-4 flex justify-end gap-2 items-center">
            <Button type="button" onClick={onCancel}>Cancel</Button>
            <Button type="submit">Submit</Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default CustomerPartNumberDialog;
