/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { RequestStatus, Rolling } from "../../../../../../types/types";
import { dateStringShorten } from "../../../../../../helpers/dateHelpers";
import { numberFormatter } from "../../../../../../helpers/numberFormat";
import TextButton from "../../../../../../components/atoms/TextButton";
import { FetchOpenRollingsOrdersRequest } from "../../../../../../store/rollings.actions";
import { SerializedStyles, css } from "@emotion/react";

type Props = {
  children?: ReactNode;
  rollings?: Rolling[];
  onChange: (params: FetchOpenRollingsOrdersRequest) => void;
  onPlannedStockChange: (params: FetchOpenRollingsOrdersRequest) => void;
  selectedSite: string;
  status: RequestStatus;
};

const BookingTable: React.FC<Props> = ({
  rollings,
  onChange,
  onPlannedStockChange,
  selectedSite,
  status,
}) => {
  return (
    <>
      <div className="max-h-[300px] overflow-y-auto mt-1">
        <table className="ntp-portal-table"
          css={tw`w-full table-fixed`}>
          <thead>
            <tr css={rowWidth}>
              <th>Start</th>
              <th>Stop</th>
              <th>MO#</th>
              <th>Property</th>
              <th>
                Planned
                <br />
                Roll Wgt
              </th>
              <th>
                Sched
                <br />
                Orders
              </th>
              <th>
                Planned
                <br />
                Stock
              </th>
              <th>
                Planned
                <br />
                VMI
              </th>
              <th>
                Avail
                <br />
                Wgt
              </th>
              <th>Status</th>
              <th>Mill</th>
              <th>
                Max
                <br />
                Lgth
              </th>
              <th>STA</th>
              <th>
                STA
                <br />
                Min
              </th>
              <th>POC</th>
            </tr>
          </thead>
          <tbody>
            {rollings
              ?.filter((item) => {
                if (selectedSite === "ALL") return true;
                return item.site === selectedSite;
              })
              ?.map((rolling) => (
                <tr key={`${rolling.rollingCycleId}${rolling.cycleLinePerSequence}`}>
                  <td>
                    <div className="flex items-center justify-between">
                      <span css={tw`w-[14px]`}>
                        {rolling.prodCreated ? "✓" : ""}
                      </span>
                      <span>{dateStringShorten(rolling.startDate)}</span>
                      <span css={tw`w-[14px]`}></span>
                    </div>
                  </td>
                  <td css={tw`text-center`}>{dateStringShorten(rolling.stopDate)}</td>
                  <td>{`${rolling.rollingCycleId}-${rolling.cycleLinePerSequence}`}</td>
                  <td>{rolling.property}</td>
                  <td css={tw`text-right`}>
                    {rolling.currentPlannedRollWgt
                      ? numberFormatter(rolling.currentPlannedRollWgt)
                      : ""}
                  </td>
                  <td css={tw`text-right`}>
                    {rolling.schedOrders ? (
                      <TextButton
                        disabled={status === "pending"}
                        onClick={() =>
                          onChange({
                            rollingCycleId: rolling.rollingCycleId,
                            rollingSequence: rolling.cycleLinePerSequence,
                            identifier: `${rolling.rollingCycleId}-${rolling.cycleLinePerSequence}`,
                          })
                        }
                      >
                        {numberFormatter(rolling.schedOrders)}
                      </TextButton>
                    ) : (
                      ""
                    )}
                  </td>
                  <td css={tw`text-right`}>
                    {rolling.plannedStock ? (
                        <TextButton
                          disabled={status === "pending"}
                          onClick={() =>
                            onPlannedStockChange({
                              rollingCycleId: rolling.rollingCycleId,
                              rollingSequence: rolling.cycleLinePerSequence,
                              identifier: `${rolling.rollingCycleId}-${rolling.cycleLinePerSequence}`,
                            })
                          }
                        >
                          {numberFormatter(rolling.plannedStock)}
                        </TextButton>
                      ) : (
                        ""
                      )}
                  </td>
                  <td css={tw`text-right`}>
                    {rolling.plannedVmi ? (
                      <TextButton
                        disabled={status === "pending"}
                        onClick={() =>
                          onChange({
                            vmi: true,
                            rollingCycleId: rolling.rollingCycleId,
                            rollingSequence: rolling.cycleLinePerSequence,
                            identifier: `${rolling.rollingCycleId}-${rolling.cycleLinePerSequence}`,
                          })
                        }
                      >
                        {numberFormatter(rolling.plannedVmi)}
                      </TextButton>
                    ) : (
                      ""
                    )}
                  </td>
                  <td css={tw`text-right`}>
                    {rolling.availableWgt
                      ? numberFormatter(rolling.availableWgt)
                      : ""}
                  </td>
                  <td>{rolling.portalRollingStatusAbbreviation}</td>
                  <td>{rolling.resource}</td>
                  <td>{rolling.maxLengthDescription}</td>
                  <td css={tw`text-center`}>
                    {rolling.subjectToAccumulation ? "YES" : "NO"}
                  </td>
                  <td>{rolling.staMinimum}</td>
                  <td css={tw`text-center`}>
                    {rolling.productOfCoil ? "YES" : "NO"}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BookingTable;

const rowWidth: SerializedStyles = css`
  & > th:nth-of-type(1) {
    width: 7%;
  }
  & > th:nth-of-type(2) {
    width: 7%;
  }
  & > th:nth-of-type(3) {
    width: 10%;
  }
  & > th:nth-of-type(4) {
    width: 7%;
  }
  & > th:nth-of-type(5) {
    width: 7%;
  }
  & > th:nth-of-type(6) {
    width: 7%;
  }
  & > th:nth-of-type(7) {
    width: 7%;
  }
  & > th:nth-of-type(8) {
    width: 7%;
  }
  & > th:nth-of-type(9) {
    width: 7%;
  }
  & > th:nth-of-type(10) {
    width: 5%;
  }
  & > th:nth-of-type(11) {
    width: 9%;
  }
  & > th:nth-of-type(12) {
    width: 4%;
  }
  & > th:nth-of-type(13) {
    width: 4%;
  }
  & > th:nth-of-type(14) {
    width: 6%;
  }
  & > th:nth-of-type(15) {
    width: 4%;
  }
`;
